export type Permission = {
  canCreate: boolean;
  canRead: boolean;
  canEdit: boolean;
  canDelete: boolean;
  dismissed: boolean;
  keyId: number;
  entityDescription: string;
  entityType: string;
};

export enum Entity {
  building = "building",
  businessUnit = "business_unit",
  company = "company",
  companyTaxConfiguration = "company_tax_configuration",
  budgetClass = "budget_class",
  country = "countries",
  customer = "customer",
  discipline = "discipline",
  trainingTarget = "training_target",
  honorificTitle = "honorificTitles",
  level = "level",
  profession = "profession",
  microarea = "project_micro_area",
  macroarea = "project_macro_area",
  participant = "participant",
  paymentCode = "payment_code",
  portalRole = "portal_role",
  portalUser = "portal_user",
  portalUserStaff = "portal_user_staff",
  projectRole = "project_role",
  projectStatus = "project_status",
  provider = "provider",
  space = "space",
  staff = "staff",
  supplier = "supplier",
  taxCode = "taxCode",
  taxContext = "tax_context",
  team = "team",
  trainingSubtype = "training_subtype",
  trainingType = "training_type",
  venue = "luogo",
}

export const usePermissions = () => {
  const getPermissions = (entityName: Entity) => {
    const permissions = {
      canRead: true,
      canEdit: true,
      canDelete: true,
      canCreate: true,
    };
    if (localStorage.getItem("role")) {
      const permissionFound = JSON.parse(
        localStorage.getItem("role")!
      ).permissions?.find(
        (permission: Permission) => permission.entityDescription === entityName
      );
      if (permissionFound) {
        permissions.canRead = !!permissionFound?.canRead;
        permissions.canEdit = !!permissionFound?.canEdit;
        permissions.canDelete = !!permissionFound?.canDelete;
        permissions.canCreate = !!permissionFound?.canCreate;
      }
    }
    return permissions;
  };

  const canCreate = (entityName: Entity) =>
    getPermissions(entityName)?.canCreate;
  const canEdit = (entityName: Entity) => getPermissions(entityName)?.canEdit;
  const canDelete = (entityName: Entity) =>
    getPermissions(entityName)?.canDelete;
  const canRead = (entityName: Entity) => getPermissions(entityName)?.canRead;

  return {
    canCreate,
    canDelete,
    canEdit,
    canRead,
  };
};
