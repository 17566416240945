import { computed } from "vue";
import useSWRV from "swrv";
import { useI18n } from "vue-i18n";
import { TabStripTabProperties } from "@progress/kendo-vue-layout";

import { flatten, unflatten } from "@/vendor/flattenizer";
import router from "@/router";

export type Project = {
  id: string;
  main: {
    companyName: string;
    filial?: string;
    title: string;
    dateStart: string;
    dateEnd: string;
    nickname?: string;
    paxNumber: number;
    isClosedNumber?: boolean;
    isMultisponsor?: boolean;
    isInternational?: boolean;
    isVirtual?: boolean;
    isScientific?: boolean;
    venue?: string;
    venueLat?: string;
    venueLng?: string;
  };
  customerInfo: {
    name: string;
    referent?: string;
    account?: string;
    division?: string;
  };
  categories: {
    state: string;
    microarea: string;
    macroarea: string;
    scope: string;
    taxContextId: string;
  };
  modules: string[];
  team: string[];
};

export const useProjects = () => {
  const { t } = useI18n();
  const { data: projects, mutate } = useSWRV("/projects");

  const currentProject = computed(() => {
    const projectId = router.currentRoute.value.params.projectId;
    if (projectId) {
      const project = projects.value?.find(
        (project: Project) => project.id === projectId
      );
      if (project) {
        return project;
      }
    }
    return null;
  });

  const flatProjects = computed(() =>
    projects.value?.map((project: Project) => ({
      id: project.id,
      ...project.main,
      ...project.customerInfo,
      ...project.categories,
      modules: project.modules,
      team: project.team,
    }))
  );

  const deleteProject = async (id: string) => {
    await fetch(`/projects/${id}`, {
      method: "DELETE",
    });
    await mutate();
  };

  const createProject = async (data: Omit<Project, "id">) => {
    const res = await fetch("/projects", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    await mutate();
    return res;
  };

  const editProject = async (data: Project) => {
    await fetch(`/projects/${data.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    await mutate();
  };

  const flattenizerKeys: (keyof Project)[] = [
    "main",
    "customerInfo",
    "categories",
  ];

  const flattenProject = (project: Project) =>
    flatten(project, flattenizerKeys) as any;

  const unflattenProject = (flatData: any) =>
    unflatten(flatData, flattenizerKeys) as Project;

  const getProjectName = (id: string) =>
    projects.value?.find((project: Project) => project.id === id)?.main.title;

  const getProjectCompany = (id: string) =>
    projects.value?.find((project: Project) => project.id === id)?.main
      .companyName;

  const getProject = (id: string) =>
    projects.value?.find((project: Project) => project.id === id);
  const projectPanelTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "mainForm",
    },
    {
      id: "1",
      title: t("pages.projectDashboard.form.tabs.customerInfo.name"),
      content: "customerInfoForm",
    },
    {
      id: "2",
      title: t("pages.projectDashboard.form.tabs.categories.name"),
      content: "categoriesForm",
    },
  ];

  return {
    projects,
    currentProject,
    deleteProject,
    createProject,
    editProject,
    flatProjects,
    flattenProject,
    unflattenProject,
    projectPanelTabs,
    getProjectName,
    getProjectCompany,
    getProject,
  };
};
