import { computed, Ref } from "vue";

import { baseUrl } from "@/config";

import { useMyFetch, useMySWRV } from "./useMySWRV";

export type BusinessUnit = {
  companyId: string;
  description: string;
  code: string;
  address: string;
  city: string;
  keyId: string;
  countryIso3: string;
};

export type ApiBusinessUnit = BusinessUnit;

export const apiToBusinessUnit = (
  businessUnitApi: ApiBusinessUnit | Omit<ApiBusinessUnit, "keyId">
) => businessUnitApi as BusinessUnit;

export const businessUnitToApi = (
  businessUnitApi: BusinessUnit | Omit<BusinessUnit, "keyId">
) => businessUnitApi as ApiBusinessUnit;
export const useBusinessUnits = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();

  const {
    data,
    mutate,
    isValidating: isValidatingBusinessUnits,
    error: errorBusinessUnits,
  } = useMySWRV(`${baseUrl}/businessunits`, {
    urlParams: options?.urlParams,
    headers: options?.headers,
    enabled: options?.enabled,
  });

  const apiBusinessUnits = computed(() => data.value?.elements);

  const businessUnitsCount = computed(() => data.value?.paging.totalRecords);

  const businessUnits = computed(() =>
    apiBusinessUnits.value?.map(apiToBusinessUnit)
  );

  const createBusinessUnit = async (data: Omit<BusinessUnit, "keyId">) => {
    const response = await myFetch(`${baseUrl}/businessunits`, {
      method: "POST",
      body: businessUnitToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const deleteBusinessUnit = async (keyId: string) => {
    const response = await myFetch(`${baseUrl}/businessunits/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const editBusinessUnit = async (data: BusinessUnit) => {
    const response = await myFetch(`${baseUrl}/businessunits`, {
      method: "POST",
      body: businessUnitToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const getBusinessUnitName = (keyId: string) =>
    businessUnits.value?.find(
      (businessUnit: BusinessUnit) => businessUnit.keyId === keyId
    )?.description;

  const getBusinessUnitId = (name: string) =>
    businessUnits.value?.find(
      (businessUnit: BusinessUnit) => businessUnit.description === name
    )?.keyId;

  const getBusinessUnit = (keyId: string) =>
    businessUnits.value?.find(
      (businessUnit: BusinessUnit) => businessUnit.keyId === keyId
    );

  return {
    businessUnits,
    businessUnitsCount,
    createBusinessUnit,
    deleteBusinessUnit,
    editBusinessUnit,
    getBusinessUnit,
    getBusinessUnitName,
    getBusinessUnitId,
    isValidatingBusinessUnits,
    errorBusinessUnits,
  };
};
