/* eslint-disable */
import {
  apiToBusiness,
  Business,
  businessToApi,
} from "@/app/_resources/useBusinesses";

import {
  ApiBusinessUnit,
  apiToBusinessUnit,
  BusinessUnit,
  businessUnitToApi,
} from "@/app/_resources/useBusinessUnits";
import { Company } from "@/app/_resources/useCompanies";
import {
  ApiCompanyTeam,
  apiToCompanyTeam,
  CompanyTeam,
  companyTeamToApi,
} from "@/app/_resources/useCompanyTeams";
import { Department, formatDepartment } from "@/app/_resources/useDepartments";
import { EconomicEntity } from "@/app/_resources/useEconomicEntities";
import {
  Comment,
  CompositeCostItem,
  CostItem,
  Header,
  Label,
} from "@/app/_resources/useEconomicEntityItems";
import {
  Macroarea,
  Microarea,
  microareaToApi,
} from "@/app/_resources/useProjectCategories";
import { formatProjectUsers } from "@/app/_resources/useProjectUsers";
import {
  Discipline,
  disciplineToApi,
  Profession,
  professionToApi,
} from "@/app/_resources/useSpecializations";
import { apiToStaff, Staff } from "@/app/_resources/useStaff";
import { TaxCode, TaxContext } from "@/app/_resources/useTaxConfigurations";
import {
  FetchedTeamMember,
  formatTeamMember,
} from "@/app/_resources/useTeamMembers";
import {
  apiToPortalUser,
  PortalUser,
} from "@/app/portalUsers/_resources/usePortalUsers";

import { baseUrl } from "@/config";
import { handlerGenerator } from "@/mocks/entities";
import { realHandlerGenerator } from "@/mocks/realEntities";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";

import { rest, RESTMethods } from "msw";
import { v4 as uuidv4 } from "uuid";
import {
  Building,
  buildingToApi,
  Level,
  Space,
} from "@/app/backoffice/venues/_resources/useVenueStructures";
import {
  ApiAgendaSession,
  TimeEntry,
} from "@/app/projects/[projectId]/classes/[classId]/_resources/useAgendas";

const fakeUsers = [
  {
    email: "user@mail.com",
    password: "password",
    jwt: "userJWT",
    uuid: "userUUID",
  },
];

const maxNumberOfSessions = false;

const mockHandlers = [
  // userLogin
  rest.post(`${baseUrl}/userlogin`, async (req, res, ctx) => {
    const { email, password } = await req.json();
    const user = fakeUsers.find((user) => user.email === email);
    if (!user || user.password !== password) {
      return res(
        ctx.status(401),
        ctx.json({
          message:
            "Wrong Credential. It was not possible to complete a login to the tenant.",
        })
      );
    } else if (maxNumberOfSessions) {
      return res(
        ctx.status(409),
        ctx.json({
          message:
            "Max Number of session opened from different devices or browser",
          resetToken: "resetToken",
          userUUID: "userUUID",
        })
      );
    } else {
      return res(
        ctx.status(200),
        ctx.json({
          uuid: user.uuid,
          jwt: user.jwt,
          refreshToken: "refreshToken",
        })
      );
    }
  }),

  // resetLicence
  rest.post(`${baseUrl}/resetlicence`, async (req, res, ctx) => {
    const { userUUID, resetToken } = await req.json();
    if (userUUID === "userUUID" && resetToken === "resetToken") {
      return res(
        ctx.status(200),
        ctx.json({
          message:
            "Session has been reset. New JWT returned with a new reset token.",
          jwt: "userJWT",
          uuid: "userUUID",
          refreshToken: "refreshToken",
        })
      );
    } else {
      return res(
        ctx.status(404),
        ctx.json({
          message: "Reset Token not valid or does not exists.",
        })
      );
    }
  }),

  // userlogout
  rest.post(`${baseUrl}/userlogout`, async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        message: "Logout successful.",
      })
    );
  }),

  // refreshtoken
  rest.post(`${baseUrl}/refreshtoken`, async (req, res, ctx) => {
    const { refreshToken, userUUID } = await req.json();

    if (refreshToken === "refreshToken" && userUUID === "userUUID") {
      return res(
        ctx.status(200),
        ctx.json({
          message: "Access token refreshed successfully.",
          jwt: "userJWT",
          refreshToken: "refreshToken",
        })
      );
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Invalid refresh token.",
        })
      );
    }
  }),

  ...realHandlerGenerator(
    {
      storage: "portalRoles",
      fetched: "portalroles",
    },
    false
  ),

  rest.post(`${baseUrl}/portaluserprofiles`, async (req, res, ctx) => {
    const item = await req.json();
    const items: PortalUser[] = JSON.parse(
      window.localStorage.getItem("portalUserProfiles") || "[]"
    );
    if (item.keyId) {
      const targetIndex = items.findIndex((el: any) => el.id === item.keyId);
      items.splice(targetIndex, 1, apiToPortalUser(item));
    } else {
      item.keyId = uuidv4();
      item.portalUuid = uuidv4();
      items.push(apiToPortalUser(item));
    }
    window.localStorage.setItem("portalUserProfiles", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),

  ...realHandlerGenerator(
    {
      storage: "portalUserProfiles",
      fetched: "portaluserprofiles",
    },
    false
  ),

  ...realHandlerGenerator(
    {
      storage: "projectRoles",
      fetched: "projectroles",
    },
    false
  ),

  ...realHandlerGenerator({
    storage: "budgetClasses",
    fetched: "budgetclasses",
  }),

  ...realHandlerGenerator({ storage: "macroareas", fetched: "macroareas" }),

  ...realHandlerGenerator({ storage: "titles", fetched: "titles" }),

  // microareas
  rest.get(`${baseUrl}/microareas`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: Microarea[] = JSON.parse(
        window.localStorage.getItem("microareas") || "[]"
      );

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }
      const macroTypeCode = req.url.searchParams.get("macroTypeCode");
      if (macroTypeCode) {
        items = items.filter(
          (item) => item.macroTypeId === parseInt(macroTypeCode)
        );
      } else {
        items = [];
      }

      // Formatting
      let formattedItems = items.map(microareaToApi);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  // taxcodes
  rest.get(`${baseUrl}/taxcodes`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: TaxCode[] = JSON.parse(
        window.localStorage.getItem("taxes") || "[]"
      );

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }
      const taxContextId = req.url.searchParams.get("taxContextId");
      if (taxContextId) {
        items = items.filter(
          (item) => item.taxContextId === parseInt(taxContextId)
        );
      }

      // Formatting
      let formattedItems = items;

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  // deparments
  rest.get(`${baseUrl}/departments`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: Department[] = JSON.parse(
        window.localStorage.getItem("departments") || "[]"
      );

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }
      const businessId = req.url.searchParams.get("businessId");
      if (businessId) {
        items = items.filter((item) => item.businessId === businessId);
      } else {
        items = [];
      }

      // Formatting
      let formattedItems = items.map(formatDepartment);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  rest.get(`${baseUrl}/businesses`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: Business[] = JSON.parse(
        window.localStorage.getItem("businesses") || "[]"
      );

      // Formatting
      let formattedItems = items.map(businessToApi);

      //filtering
      if (req.url.searchParams.get("isSupplier") === "false") {
        items = items.filter((el) => !el.isSupplier);
      }
      if (req.url.searchParams.get("isCustomer") === "false") {
        items = items.filter((el) => !el.isCustomer);
      }
      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: 0,
          pageSize: 0,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(403),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  ...realHandlerGenerator({ storage: "departments", fetched: "departments" }),

  ...realHandlerGenerator({ storage: "microareas", fetched: "microareas" }),

  ...realHandlerGenerator({ storage: "companies", fetched: "companies" }),

  ...realHandlerGenerator({
    storage: "businesses",
    fetched: "businesses",
  }),

  ...realHandlerGenerator({
    storage: "projectStates",
    fetched: "projectstatuses",
  }),

  ...realHandlerGenerator({
    storage: "taxes",
    fetched: "taxcodes",
  }),

  ...realHandlerGenerator({
    storage: "taxContexts",
    fetched: "taxcontexts",
  }),

  // countries
  ...realHandlerGenerator({
    storage: "countries",
    fetched: "countries",
  }),

  ...realHandlerGenerator({
    storage: "disciplines",
    fetched: "disciplines",
  }),

  ...realHandlerGenerator({
    storage: "jobs",
    fetched: "professions",
  }),

  ...realHandlerGenerator(
    {
      storage: "projectEntities",
      fetched: "projectentities",
    },
    false
  ),

  rest.post(`${baseUrl}/staff`, async (req, res, ctx) => {
    const item = await req.json();
    const items: Staff[] = JSON.parse(
      window.localStorage.getItem("staff") || "[]"
    );
    if (item.keyId) {
      const targetIndex = items.findIndex((el: any) => el.id === item.keyId);
      items.splice(targetIndex, 1, apiToStaff(item));
    } else {
      item.keyId = uuidv4();
      if (item.companyId) {
        item.companies = [{ keyId: item.companyId }];
        delete item.companyId;
      }
      items.push(apiToStaff(item));
    }
    window.localStorage.setItem("staff", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),

  ...realHandlerGenerator(
    {
      storage: "staff",
      fetched: "staff",
    },
    false,
    [RESTMethods.DELETE, RESTMethods.GET]
  ),

  // customers
  rest.get(`${baseUrl}/customers`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();

    if (authorizationtoken === "Bearer userJWT") {
      const businesses = JSON.parse(
        window.localStorage.getItem("businesses") || "[]"
      );

      let items = businesses?.filter((el: Business) => el.isCustomer);

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }

      // Formatting
      let formattedItems = items.map(businessToApi);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  rest.delete(`${baseUrl}/customers/:keyId`, async (req, res, ctx) => {
    const { keyId } = req.params;
    const items: Business[] = JSON.parse(
      window.localStorage.getItem("businesses") || "[]"
    );
    const targetIndex = items.findIndex((el: any) => el.id === keyId);
    if (items[targetIndex].isSupplier) {
      window.localStorage.setItem(
        "businesses",
        JSON.stringify(
          items.map((item, index) => {
            if (index === targetIndex) {
              return { ...item, isCustomer: false };
            } else {
              return item;
            }
          })
        )
      );
    } else {
      items.splice(targetIndex, 1);
      window.localStorage.setItem("businesses", JSON.stringify(items));
    }
    return res(ctx.status(200), ctx.json({}));
  }),

  rest.post(`${baseUrl}/customers`, async (req, res, ctx) => {
    const item = await req.json();
    let items: Business[] = JSON.parse(
      window.localStorage.getItem("businesses") || "[]"
    );
    if (item.keyId) {
      const targetIndex = items.findIndex((el: any) => el.id === item.keyId);
      if (item.isSupplier) {
        items = items.map((value, index) => {
          if (index === targetIndex) {
            return { ...value, isCustomer: true };
          } else return value;
        });
      } else {
        items.splice(targetIndex, 1, apiToBusiness(item));
      }
    } else {
      item.keyId = uuidv4();
      items.push(apiToBusiness({ ...item, isCustomer: true }));
    }
    window.localStorage.setItem("businesses", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),

  // suppliers
  rest.get(`${baseUrl}/suppliers`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();

    if (authorizationtoken === "Bearer userJWT") {
      const businesses = JSON.parse(
        window.localStorage.getItem("businesses") || "[]"
      );

      let items = businesses?.filter((el: Business) => el.isSupplier);

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }

      // Formatting
      let formattedItems = items.map(businessToApi);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  rest.delete(`${baseUrl}/suppliers/:keyId`, async (req, res, ctx) => {
    const { keyId } = req.params;
    const items: Business[] = JSON.parse(
      window.localStorage.getItem("businesses") || "[]"
    );
    const targetIndex = items.findIndex((el: any) => el.id === keyId);
    if (items[targetIndex].isCustomer) {
      window.localStorage.setItem(
        "businesses",
        JSON.stringify(
          items.map((item, index) => {
            if (index === targetIndex) {
              return { ...item, isSupplier: false };
            } else {
              return item;
            }
          })
        )
      );
    } else {
      items.splice(targetIndex, 1);
      window.localStorage.setItem("businesses", JSON.stringify(items));
    }
    return res(ctx.status(200), ctx.json({}));
  }),

  rest.post(`${baseUrl}/suppliers`, async (req, res, ctx) => {
    const item = await req.json();
    let items: Business[] = JSON.parse(
      window.localStorage.getItem("businesses") || "[]"
    );
    if (item.keyId) {
      const targetIndex = items.findIndex((el: any) => el.id === item.keyId);
      if (item.isCustomer) {
        items = items.map((value, index) => {
          if (index === targetIndex) {
            return { ...value, isSupplier: true };
          } else return value;
        });
      } else {
        items.splice(targetIndex, 1, apiToBusiness(item));
      }
    } else {
      item.keyId = uuidv4();
      item.isSupplier = true;
      items.push(apiToBusiness(item));
    }
    window.localStorage.setItem("businesses", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),

  // businessunits
  rest.get(`${baseUrl}/businessunits`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: ApiBusinessUnit[] = JSON.parse(
        window.localStorage.getItem("businessunits") || "[]"
      );

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }
      const companyId = req.url.searchParams.get("companyId");
      if (companyId) {
        items = items.filter((item) => item.companyId === companyId);
      } else {
        items = [];
      }

      // Formatting
      let formattedItems = items.map(businessUnitToApi);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),
  rest.post(`${baseUrl}/businessunits`, async (req, res, ctx) => {
    const item = await req.json();
    const items: BusinessUnit[] = JSON.parse(
      window.localStorage.getItem("businessunits") || "[]"
    );
    if (item.keyId) {
      const targetIndex = items.findIndex((el: any) => el.id === item.keyId);
      items.splice(targetIndex, 1, apiToBusinessUnit(item));
    } else {
      item.keyId = uuidv4();
      items.push(apiToBusinessUnit(item));
    }
    window.localStorage.setItem("businessunits", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),
  rest.delete(`${baseUrl}/businessunits/:keyId`, async (req, res, ctx) => {
    const { keyId } = req.params;
    const items: BusinessUnit[] = JSON.parse(
      window.localStorage.getItem("businessunits") || "[]"
    );
    const targetIndex = items.findIndex((el: any) => el.id === keyId);
    items.splice(targetIndex, 1);
    window.localStorage.setItem("businessunits", JSON.stringify(items));
    return res(ctx.status(200), ctx.json({}));
  }),

  // teams
  rest.get(`${baseUrl}/companyteams`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: ApiCompanyTeam[] = JSON.parse(
        window.localStorage.getItem("companyTeams") || "[]"
      );

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }
      const companyId = req.url.searchParams.get("companyId");
      if (companyId) {
        items = items.filter((item) => item.companyId === companyId);
      } else {
        items = [];
      }

      // Formatting
      let formattedItems = items.map(companyTeamToApi);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),
  rest.post(`${baseUrl}/companyteams`, async (req, res, ctx) => {
    const item = await req.json();
    const items: CompanyTeam[] = JSON.parse(
      window.localStorage.getItem("companyTeams") || "[]"
    );
    if (item.keyId) {
      const targetIndex = items.findIndex((el: any) => el.id === item.keyId);
      items.splice(targetIndex, 1, apiToCompanyTeam(item));
    } else {
      item.keyId = uuidv4();
      items.push(apiToCompanyTeam(item));
    }
    window.localStorage.setItem("companyTeams", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),
  rest.delete(`${baseUrl}/companyteams/:keyId`, async (req, res, ctx) => {
    const { keyId } = req.params;
    const items: CompanyTeam[] = JSON.parse(
      window.localStorage.getItem("companyTeams") || "[]"
    );
    const targetIndex = items.findIndex((el: any) => el.id === keyId);
    items.splice(targetIndex, 1);
    window.localStorage.setItem("companyTeams", JSON.stringify(items));
    return res(ctx.status(200), ctx.json({}));
  }),

  // companyteams
  rest.get(`${baseUrl}/companyteams`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: ApiCompanyTeam[] = JSON.parse(
        window.localStorage.getItem("companyTeams") || "[]"
      );

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }

      const companyId = req.url.searchParams.get("companyId");
      if (companyId) {
        items = items.filter((item) => item.companyId === companyId);
      }
      const businessUnitId = req.url.searchParams.get("businessUnitId");
      if (businessUnitId) {
        items = items.filter((item) => item.businessUnitId === businessUnitId);
      }

      if (!companyId && !businessUnitId) {
        items = [];
      }

      // Formatting
      let formattedItems = items.map(companyTeamToApi);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  // teammembers
  rest.get(`${baseUrl}/teammembers`, async (req, res, ctx) => {
    const { authorizationtoken } = req.headers.all();
    if (authorizationtoken === "Bearer userJWT") {
      let items: FetchedTeamMember[] = JSON.parse(
        window.localStorage.getItem("teamMembers") || "[]"
      );

      // Filtering
      if (req.headers.get("X-Header-Filter")) {
        const filter: CompositeFilterDescriptor = JSON.parse(
          atob(req.headers.get("X-Header-Filter") as string)
        );
        items = filterBy(items, filter);
      }

      const teamId = req.url.searchParams.get("teamId");
      if (teamId) {
        items = items.filter((item) => item.teamId === teamId);
      } else {
        items = [];
      }

      // Formatting
      let formattedItems = items.map(formatTeamMember);

      // Pagination
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }

      const response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };

      return res(ctx.status(200), ctx.json(response));
    } else {
      return res(
        ctx.status(401),
        ctx.json({
          message: "Unauthorized",
        })
      );
    }
  }),

  // projectcategories
  rest.get(`${baseUrl}/projectcategories`, async (req, res, ctx) => {
    const macroareas = JSON.parse(
      window.localStorage.getItem("macroareas") || "[]"
    );
    const microareas = JSON.parse(
      window.localStorage.getItem("microareas") || "[]"
    );
    const macroareasWithChildren = macroareas.map((macroarea: Macroarea) => {
      const children = microareas
        .filter(
          (microarea: Microarea) => microarea.macroTypeId === macroarea.keyId
        )
        .map((microarea: Microarea) => ({
          keyId: microarea.keyId,
          microarea: microarea.description,
        }));
      return {
        keyId: macroarea.keyId,
        macroarea: macroarea.description,
        microareas: children,
      };
    });
    return res(ctx.status(200), ctx.json(macroareasWithChildren));
  }),

  // specializations
  rest.get(`${baseUrl}/specializations`, async (req, res, ctx) => {
    const jobs = JSON.parse(window.localStorage.getItem("jobs") || "[]");
    const disciplines = JSON.parse(
      window.localStorage.getItem("disciplines") || "[]"
    );
    const jobsWithChildren = jobs.map((job: Profession) => ({
      ...professionToApi(job),
      disciplines: disciplines
        .filter(
          (discipline: Discipline) => discipline.professionId === job.keyId
        )
        ?.map(disciplineToApi),
    }));
    return res(ctx.status(200), ctx.json(jobsWithChildren));
  }),

  // taxconfigurations
  rest.get(`${baseUrl}/taxconfigurations`, async (req, res, ctx) => {
    const taxContexts = JSON.parse(
      window.localStorage.getItem("taxContexts") || "[]"
    );
    const taxCodes = JSON.parse(window.localStorage.getItem("taxes") || "[]");
    const taxContextsWithChildren = taxContexts.map(
      (taxContext: TaxContext) => {
        const children = taxCodes
          .filter(
            (taxCode: TaxCode) => taxCode.taxContextId === taxContext.keyId
          )
          .map((taxCode: TaxCode) => ({
            keyId: taxCode.keyId,
            description: taxCode.taxDescription,
          }));
        return {
          keyId: taxContext.keyId,
          taxContextDescription: taxContext.taxContextDescription,
          taxCodes: children,
        };
      }
    );
    return res(ctx.status(200), ctx.json(taxContextsWithChildren));
  }),

  //projectusers
  rest.get(`${baseUrl}/projectusers`, async (req, res, ctx) => {
    const portalUsers = JSON.parse(
      window.localStorage.getItem("teamMembers") || "[]"
    );

    const staff = JSON.parse(window.localStorage.getItem("staff") || "[]");

    const roles = JSON.parse(
      window.localStorage.getItem("projectRoles") || "[]"
    );

    const projectUsers = {
      roles,
      staff: staff.concat(portalUsers),
    };

    return res(ctx.status(200), ctx.json(formatProjectUsers(projectUsers)));
  }),

  rest.post("/updateEconomicEntityState", async (req, res, ctx) => {
    const { economicEntityId, state } = await req.json();

    const economicEntity = await (
      await fetch(`/economicEntities/${economicEntityId}`)
    ).json();

    const { state: previousState, type: economicEntityType } = economicEntity;

    if (state === "rejected") {
      // Update sponsor estimates states
      const sponsorEstimates = JSON.parse(
        window.localStorage.getItem("economicEntities") || "[]"
      )?.filter(
        (economicEntity: EconomicEntity) =>
          economicEntity.parentId === economicEntityId
      );

      if (sponsorEstimates) {
        for (const sponsorEstimate of sponsorEstimates) {
          await fetch(`/economicEntities/${sponsorEstimate.id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ state }),
          });
        }
      }
    }

    // if (state !== "accepted" && previousState === "accepted") {
    //   // await clearBudgetItems();
    //   // await clearSponsorBudgets();
    //   // await clearSponsorBudgetItems();
    // }

    await fetch(`/economicEntities/${economicEntityId}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ state }),
    });

    if (
      state === "accepted" &&
      previousState !== "accepted" &&
      economicEntityType === "estimate"
    ) {
      // Reject other estimates
      const estimates = JSON.parse(
        window.localStorage.getItem("economicEntities") || "[]"
      )?.filter(
        (el: EconomicEntity) =>
          el.projectId === economicEntity.projectId &&
          el.type === "estimate" &&
          el.id !== economicEntityId
      );

      if (estimates) {
        for (const estimate of estimates) {
          await fetch(`/economicEntities/${estimate.id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ state: "rejected" }),
          });
        }
      }

      // Create the budget economic entity
      const budgetEconomicEntity = await (
        await fetch("/economicEntities", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...economicEntity,
            state: "inProgress",
            type: "budget",
          }),
        })
      ).json();

      // Create the budget headers
      const headers = JSON.parse(localStorage.getItem("headers") || "[]");

      const estimateHeaders = headers.filter(
        (header: Header) => header.economicEntityId === economicEntityId
      );

      const budgetHeaders: Omit<Header, "id">[] = estimateHeaders?.map(
        (el: Header) => {
          const budgetHeader = {
            ...el,
            economicEntityId: budgetEconomicEntity.id,
            comparisonId: el.id,
          };
          //@ts-ignore
          delete budgetHeader.id;

          return budgetHeader;
        }
      );

      const createBudgetHeader = async (data: Omit<Header, "id">) => {
        const response = await fetch("/headers", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(budgetHeaders?.map(createBudgetHeader));

      const updatedBudgetHeaders: Header[] = JSON.parse(
        localStorage.getItem("headers") || "[]"
      ).filter(
        (header: Header) => header.economicEntityId === budgetEconomicEntity.id
      );

      // Create the budget labels
      const getBudgetHeaderFromEstimateHeader = (estimateHeaderId: string) => {
        const budgetHeader = updatedBudgetHeaders.find(
          (el) => el.comparisonId === estimateHeaderId
        );
        return budgetHeader?.id;
      };

      const labels = JSON.parse(localStorage.getItem("labels") || "[]");

      const estimateLabels = labels.filter(
        (label: Label) => label.economicEntityId === economicEntityId
      );

      const budgetLabels: Omit<Label, "id">[] = estimateLabels?.map(
        (el: Label) => {
          const budgetLabel = {
            ...el,
            economicEntityId: budgetEconomicEntity.id,
            comparisonId: el.id,
            headerId: getBudgetHeaderFromEstimateHeader(el.headerId),
          };
          //@ts-ignore
          delete budgetLabel.id;
          return budgetLabel;
        }
      );

      const createBudgetLabel = async (data: Omit<Label, "id">) => {
        const response = await fetch("/labels", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(budgetLabels?.map(createBudgetLabel));

      // Update labels
      const updatedBudgetLabels: Label[] = JSON.parse(
        localStorage.getItem("labels") || "[]"
      ).filter(
        (label: Label) => label.economicEntityId === budgetEconomicEntity.id
      );

      const getBudgetLabelFromEstimateLabel = (estimateLabelId: string) => {
        const budgetLabel = updatedBudgetLabels.find(
          (el) => el.comparisonId === estimateLabelId
        );
        return budgetLabel?.id;
      };

      // Create the budget cost items
      const costItems = JSON.parse(localStorage.getItem("costItems") || "[]");

      const estimateCostItems = costItems.filter(
        (costItem: CostItem) => costItem.economicEntityId === economicEntityId
      );

      const estimatePackages = estimateCostItems.filter(
        (el: CostItem) => el.isPackage
      );

      const budgetPackages: Omit<CostItem, "id">[] = estimatePackages?.map(
        (el: CostItem) => {
          const budgetPackage = {
            ...el,
            comparisonId: el.id,
            economicEntityId: budgetEconomicEntity.id,
            labelId: el.labelId
              ? getBudgetLabelFromEstimateLabel(el.labelId)
              : undefined,
          };
          //@ts-ignore
          delete budgetPackage.id;
          return budgetPackage;
        }
      );

      const createBudgetCostItem = async (data: Omit<CostItem, "id">) => {
        const response = await fetch("/costItems", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response.json();
      };

      await Promise.all(budgetPackages?.map(createBudgetCostItem));

      const estimateCompositeCostItems = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter(
        (el: CompositeCostItem) => el.economicEntityId === economicEntityId
      );

      let updatedBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter(
        (el: CostItem) => el.economicEntityId === budgetEconomicEntity.id
      );

      const getBudgetCostItemFromEstimateCostItem = (
        estimateCostItemId: string
      ) => {
        const budgetCostItem = updatedBudgetCostItems.find(
          (el: CostItem) => el.comparisonId === estimateCostItemId
        );
        return budgetCostItem?.id;
      };

      const budgetCompositeCostItems: Omit<CompositeCostItem, "id">[] =
        estimateCompositeCostItems?.map((el: CompositeCostItem) => {
          const budgetCompositeCostItem = {
            ...el,
            packageId: getBudgetCostItemFromEstimateCostItem(el.packageId),
            economicEntityId: budgetEconomicEntity.id,
            comparisonId: el.id,
          };
          //@ts-ignore
          delete budgetCompositeCostItem.id;
          return budgetCompositeCostItem;
        });

      const createBudgetCompositeCostItem = async (
        data: Omit<CompositeCostItem, "id">
      ) => {
        const response = await fetch("/compositeCostItems", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(
        budgetCompositeCostItems?.map(createBudgetCompositeCostItem)
      );

      updatedBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter(
        (el: CostItem) => el.economicEntityId === budgetEconomicEntity.id
      );

      const updatedBudgetCompositeCostItems: CompositeCostItem[] = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter(
        (el: CompositeCostItem) =>
          el.economicEntityId === budgetEconomicEntity.id
      );

      const getBudgetCompositeCostItemFromEstimateCompositeCostItem = (
        estimateCompositeCostItemId: string
      ) => {
        const budgetCompositeCostItem = updatedBudgetCompositeCostItems.find(
          (el: CompositeCostItem) =>
            el.comparisonId === estimateCompositeCostItemId
        );
        return budgetCompositeCostItem?.id;
      };

      const estimateBaseCostItems = estimateCostItems.filter(
        (el: CostItem) => !el.isPackage
      );

      const budgetBaseCostItems: Omit<CostItem, "id">[] =
        estimateBaseCostItems?.map((el: CostItem) => {
          const budgetCostItem = {
            ...el,
            comparisonId: el.id,
            labelId: el.labelId
              ? getBudgetLabelFromEstimateLabel(el.labelId)
              : undefined,
            economicEntityId: budgetEconomicEntity.id,
          };
          if (budgetCostItem.packageId) {
            budgetCostItem.packageId = getBudgetCostItemFromEstimateCostItem(
              budgetCostItem.packageId
            );
            if (budgetCostItem.compositeCostItemId) {
              budgetCostItem.compositeCostItemId =
                getBudgetCompositeCostItemFromEstimateCompositeCostItem(
                  budgetCostItem.compositeCostItemId
                );
            }
          }
          //@ts-ignore
          delete budgetCostItem.id;
          return budgetCostItem;
        });

      await Promise.all(budgetBaseCostItems?.map(createBudgetCostItem));

      const estimateComments = JSON.parse(
        localStorage.getItem("comments") || "[]"
      ).filter((el: Comment) => el.economicEntityId === economicEntityId);

      const budgetComments = estimateComments?.map((el: Comment) => {
        const budgetComment = {
          ...el,
          labelId: getBudgetLabelFromEstimateLabel(el.labelId),
          economicEntityId: budgetEconomicEntity.id,
        };
        //@ts-ignore
        delete budgetComment.id;
        return budgetComment;
      });
      const createBudgetComment = async (data: Omit<Comment, "id">) => {
        const response = await fetch("/comments", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response;
      };

      await Promise.all(budgetComments?.map(createBudgetComment));

      //Generate Budget Sponsors

      const sponsorEstimates = JSON.parse(
        localStorage.getItem("economicEntities") || "[]"
      ).filter((el: EconomicEntity) => el.parentId === economicEntityId);

      const sponsorBudgets = sponsorEstimates?.map((el: EconomicEntity) => {
        const sponsorBudget = {
          ...el,
          comparisonId: el.id,
          parentId: budgetEconomicEntity.id,
        };
        //@ts-ignore
        delete sponsorBudget.id;
        return sponsorBudget;
      });

      const createSponsorBudget = async (data: Omit<EconomicEntity, "id">) => {
        const response = await fetch("/economicEntities", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response.json();
      };

      await Promise.all(sponsorBudgets?.map(createSponsorBudget));

      const updatedSponsorBudgets = JSON.parse(
        localStorage.getItem("economicEntities") || "[]"
      ).filter((el: EconomicEntity) => el.parentId === budgetEconomicEntity.id);

      const getSponsorBudgetFromSponsorEstimate = (
        sponsorEstimateId: string
      ) => {
        const sponsorBudget = updatedSponsorBudgets.find(
          (el: EconomicEntity) => el.comparisonId === sponsorEstimateId
        );
        return sponsorBudget?.id;
      };

      const sponsorEstimateIds = sponsorEstimates.map(
        (el: EconomicEntity) => el.id
      );

      const sponsorBudgetIds = updatedSponsorBudgets.map(
        (el: EconomicEntity) => el.id
      );

      const sponsorEstimateHeaders = JSON.parse(
        localStorage.getItem("headers") || "[]"
      ).filter((el: Header) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorBudgetHeaders: Omit<Header, "id">[] =
        sponsorEstimateHeaders.map((el: Header) => {
          const budgeSponsorHeader = {
            ...el,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
            comparisonId: el.id,
          };
          //@ts-ignore
          delete budgeSponsorHeader.id;
          return budgeSponsorHeader;
        });

      await Promise.all(sponsorBudgetHeaders?.map(createBudgetHeader));

      const updatedSponsorBudgetHeaders = JSON.parse(
        localStorage.getItem("headers") || "[]"
      ).filter((el: Header) => sponsorBudgetIds.includes(el.economicEntityId));

      const getSponsorBudgetHeaderFromSponsorEstimateHeader = (
        sponsorEstimateHeaderId: string
      ) => {
        const sponsorBudgetHeader = updatedSponsorBudgetHeaders.find(
          (el: Header) => el.comparisonId === sponsorEstimateHeaderId
        );
        return sponsorBudgetHeader?.id;
      };

      const sponsorEstimateLabels = JSON.parse(
        localStorage.getItem("labels") || "[]"
      ).filter((el: Header) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorBudgetLabels: Omit<Label, "id">[] =
        sponsorEstimateLabels?.map((el: Label) => {
          const sponsorBudgetLabel = {
            ...el,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
            comparisonId: el.id,
            headerId: getSponsorBudgetHeaderFromSponsorEstimateHeader(
              el.headerId
            ),
          };
          //@ts-ignore
          delete sponsorBudgetLabel.id;
          return sponsorBudgetLabel;
        });

      await Promise.all(sponsorBudgetLabels?.map(createBudgetLabel));

      const updatedSponsorBudgetLabels = JSON.parse(
        localStorage.getItem("labels") || "[]"
      ).filter((el: Label) => sponsorBudgetIds.includes(el.economicEntityId));

      const getSponsorBudgetLabelFromSponsorEstimateLabel = (
        sponsorEstimateLabelId: string
      ) => {
        const sponsorBudgetLabel = updatedSponsorBudgetLabels.find(
          (el: Label) => el.comparisonId === sponsorEstimateLabelId
        );
        return sponsorBudgetLabel?.id;
      };

      const sponsorEstimateCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter((el: CostItem) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorEstimatePackages = sponsorEstimateCostItems.filter(
        (el: CostItem) => el.isPackage
      );

      const sponsorBudgetPackages: Omit<CostItem, "id">[] =
        sponsorEstimatePackages?.map((el: CostItem) => {
          const sponsorBudgetPackage = {
            ...el,
            comparisonId: el.id,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
            labelId: el.labelId
              ? getSponsorBudgetLabelFromSponsorEstimateLabel(el.labelId)
              : undefined,
          };
          //@ts-ignore
          delete sponsorBudgetPackage.id;
          return sponsorBudgetPackage;
        });

      await Promise.all(sponsorBudgetPackages?.map(createBudgetCostItem));

      const sponsorEstimateCompositeCostItems = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter((el: CompositeCostItem) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      let updatedSponsorBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter((el: CostItem) =>
        sponsorBudgetIds.includes(el.economicEntityId)
      );

      const getSponsorBudgetCostItemFromSponsorEstimateCostItem = (
        sponsorEstimateCostItemId: string
      ) => {
        const sponsorBudgetCostItem = updatedSponsorBudgetCostItems.find(
          (el: CostItem) => el.comparisonId === sponsorEstimateCostItemId
        );
        return sponsorBudgetCostItem?.id;
      };

      const sponsorBudgetCompositeCostItems: Omit<CompositeCostItem, "id">[] =
        sponsorEstimateCompositeCostItems?.map((el: CompositeCostItem) => {
          const sponsorBudgetCompositeCostItem = {
            ...el,
            packageId: getSponsorBudgetCostItemFromSponsorEstimateCostItem(
              el.packageId
            ),
            comparisonId: el.id,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
          };
          //@ts-ignore
          delete sponsorBudgetCompositeCostItem.id;

          return sponsorBudgetCompositeCostItem;
        });

      await Promise.all(
        sponsorBudgetCompositeCostItems?.map(createBudgetCompositeCostItem)
      );

      updatedSponsorBudgetCostItems = JSON.parse(
        localStorage.getItem("costItems") || "[]"
      ).filter((el: CostItem) =>
        sponsorBudgetIds.includes(el.economicEntityId)
      );

      const updatedSponsorBudgetCompositeCostItems = JSON.parse(
        localStorage.getItem("compositeCostItems") || "[]"
      ).filter((el: CompositeCostItem) =>
        sponsorBudgetIds.includes(el.economicEntityId)
      );

      const getSponsorBudgetCompositeCostItemFromSponsorEstimateCompositeCostItem =
        (sponsorEstimateCompositeCostItemId: string) => {
          const sponsorBudgetCompositeCostItem =
            updatedSponsorBudgetCompositeCostItems.find(
              (el: CompositeCostItem) =>
                el.comparisonId === sponsorEstimateCompositeCostItemId
            );
          return sponsorBudgetCompositeCostItem?.id;
        };

      const sponsorEstimateBaseCostItems = sponsorEstimateCostItems?.filter(
        (el: CostItem) => !el.isPackage
      );

      const sponsorBudgetBaseCostItems: Omit<CostItem, "id">[] =
        sponsorEstimateBaseCostItems?.map((el: CostItem) => {
          const sponsorBudgetCostItem = {
            ...el,
            labelId: el.labelId
              ? getSponsorBudgetLabelFromSponsorEstimateLabel(el.labelId)
              : undefined,
            comparisonId: el.id,
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
          };
          if (sponsorBudgetCostItem.packageId) {
            sponsorBudgetCostItem.packageId =
              getSponsorBudgetCostItemFromSponsorEstimateCostItem(
                sponsorBudgetCostItem.packageId
              );
            if (sponsorBudgetCostItem.compositeCostItemId) {
              sponsorBudgetCostItem.compositeCostItemId =
                getSponsorBudgetCompositeCostItemFromSponsorEstimateCompositeCostItem(
                  sponsorBudgetCostItem.compositeCostItemId
                );
            }
          }
          //@ts-ignore
          delete sponsorBudgetCostItem.id;
          return sponsorBudgetCostItem;
        });

      await Promise.all(sponsorBudgetBaseCostItems?.map(createBudgetCostItem));

      const sponsorEstimateComments = JSON.parse(
        localStorage.getItem("comments") || "[]"
      ).filter((el: Comment) =>
        sponsorEstimateIds.includes(el.economicEntityId)
      );

      const sponsorBudgetComments = sponsorEstimateComments?.map(
        (el: Comment) => {
          const sponsorBudgetComment = {
            ...el,
            labelId: getSponsorBudgetLabelFromSponsorEstimateLabel(el.labelId),
            economicEntityId: getSponsorBudgetFromSponsorEstimate(
              el.economicEntityId
            ),
          };
          //@ts-ignore
          delete sponsorBudgetComment.id;
          return sponsorBudgetComment;
        }
      );

      await Promise.all(sponsorBudgetComments?.map(createBudgetComment));
    }
  }),

  rest.get(`${baseUrl}/geographicalscopes`, (req, res, ctx) => {
    const geographicalScopes = JSON.parse(
      localStorage.getItem("geographicalScopes") || "[]"
    );
    return res(ctx.status(200), ctx.json(geographicalScopes));
  }),

  rest.post(`${baseUrl}/companystaff`, async (req, res, ctx) => {
    let staff = JSON.parse(localStorage.getItem("staff") || "[]");

    const { companyId, staffId } = await req.json();

    staff = staff.map((el: Staff) => {
      if (el.keyId === parseInt(staffId)) {
        if (el.companies === undefined) el.companies = [];
        return {
          ...el,
          companies: el.companies.concat({ keyId: companyId }),
        };
      } else {
        return el;
      }
    });
    localStorage.setItem("staff", JSON.stringify(staff));

    return res(ctx.status(200), ctx.json(staff));
  }),

  rest.delete(`${baseUrl}/companystaff`, async (req, res, ctx) => {
    let staff = JSON.parse(localStorage.getItem("staff") || "[]");

    const { companyId, staffId } = await req.json();

    staff = staff.map((el: Staff) => {
      if (el.keyId === parseInt(staffId)) {
        return {
          ...el,
          companies: el.companies?.filter(
            (company: { keyId: string }) => company.keyId !== companyId
          ),
        };
      } else {
        return el;
      }
    });

    localStorage.setItem("staff", JSON.stringify(staff));

    return res(ctx.status(200), ctx.json(staff));
  }),

  rest.post(`${baseUrl}/businessunitstaff`, async (req, res, ctx) => {
    let staff = JSON.parse(localStorage.getItem("staff") || "[]");

    const { businessUnitId, staffId } = await req.json();

    const businessUnits = JSON.parse(
      localStorage.getItem("businessunits") || "[]"
    );

    const companyId = businessUnits.find(
      (el: BusinessUnit) => el.keyId === businessUnitId
    )?.companyId;

    if (companyId) {
      staff = staff.map((el: Staff) => {
        if (el.keyId === parseInt(staffId)) {
          return {
            ...el,
            companies: el.companies?.map((company: any) => {
              if (company.keyId === companyId) {
                if (company.businessUnits === undefined)
                  company.businessUnits = [];
                return {
                  ...company,
                  businessUnits: company.businessUnits.concat({
                    keyId: businessUnitId,
                  }),
                };
              } else {
                return company;
              }
            }),
          };
        } else {
          return el;
        }
      });
    }

    localStorage.setItem("staff", JSON.stringify(staff));

    return res(ctx.status(200), ctx.json(staff));
  }),

  rest.post(`${baseUrl}/teammember`, async (req, res, ctx) => {
    let staff = JSON.parse(localStorage.getItem("staff") || "[]");
    const { teamId, staffId } = await req.json();

    let companyTeam = JSON.parse(localStorage.getItem("companyTeams") || "[]");
    const targetTeam = companyTeam.find(
      (el: CompanyTeam) => el.keyId === teamId
    );
    const companyId = targetTeam?.companyId;
    const businessUnitId = targetTeam?.businessUnitId;
    staff = staff.map((el: Staff) => {
      const targetCompany = el.companies?.find(
        (company) => company.keyId === companyId
      );
      if (targetCompany) {
        if (businessUnitId) {
          return {
            ...el,
            companies: el.companies?.map((company: any) => {
              if (company.keyId === companyId) {
                return {
                  ...company,
                  businessUnits:
                    company.businessUnits?.map((businessUnit: any) => {
                      if (businessUnit.keyId === businessUnitId) {
                        if (businessUnit.teams === undefined)
                          businessUnit.teams = [];
                        return {
                          ...businessUnit,
                          teams: businessUnit.teams.concat({ keyId: teamId }),
                        };
                      } else {
                        return businessUnit;
                      }
                    }) ?? [],
                };
              } else {
                return company;
              }
            }),
          };
        } else {
          return {
            ...el,
            companies: el.companies?.map((company: any) => {
              if (company.keyId === companyId) {
                if (company.teams === undefined) company.teams = [];
                return {
                  ...company,
                  teams: company.teams.concat({ keyId: teamId }),
                };
              } else {
                return company;
              }
            }),
          };
        }
      } else {
        return el;
      }
    });
    localStorage.setItem("staff", JSON.stringify(staff));
    return res(ctx.status(200), ctx.json(staff));
  }),
  rest.delete(`${baseUrl}/teammember/`, async (req, res, ctx) => {
    const { teamId, staffId } = await req.json();
    let staff = JSON.parse(localStorage.getItem("staff") || "[]");

    const targetStaffIndex = staff.findIndex(
      (el: Staff) => el.keyId === parseInt(staffId)
    );
    if (targetStaffIndex !== -1) {
      const targetStaff = staff[targetStaffIndex];
      const teams = targetStaff.companies.find(
        (company: Company) => company.keyId === teamId
      ).teams;

      const targetTeamIndex = teams.findIndex(
        (el: CompanyTeam) => el.keyId === teamId
      );
      if (targetTeamIndex !== -1) {
        teams.splice(targetTeamIndex, 1); // Rimuovi il team dallo staff

        localStorage.setItem("staff", JSON.stringify(staff));
      }
    }

    return res(ctx.status(200));
  }),
];

const newEntities = [
  ...handlerGenerator("projects"),
  ...realHandlerGenerator(
    {
      storage: "classes",
      fetched: "classes",
    },
    false
  ),
  ...realHandlerGenerator(
    {
      storage: "spaces",
      fetched: "spaces",
    },
    false,
    ["venueId"]
  ),

  ...realHandlerGenerator(
    {
      storage: "prices",
      fetched: "prices",
    },
    false
  ),
  rest.post(`${baseUrl}/agendaSessions`, async (req, res, ctx) => {
    const item = await req.json();
    const items: ApiAgendaSession[] = JSON.parse(
      window.localStorage.getItem("agendaSessions") || "[]"
    );

    const isOverlapping = (a: TimeEntry, b: TimeEntry) =>
      new Date(a.startTime) < new Date(b.endTime) &&
      new Date(a.endTime) > new Date(b.startTime);

    // Check if time entries are overlapping within the same session
    for (let i = 0; i < item.timeEntries.length; i++) {
      for (let j = i + 1; j < item.timeEntries.length; j++) {
        if (isOverlapping(item.timeEntries[i], item.timeEntries[j])) {
          return res(
            ctx.status(400),
            ctx.json({
              message: "Time entries are overlapping within the same session",
            })
          );
        }
      }
    }

    // Check if time entries are overlapping with other agenda sessions
    for (const otherItem of items) {
      if (otherItem.keyId === item.keyId) continue;
      for (const timeEntry1 of item.timeEntries) {
        for (const timeEntry2 of otherItem.timeEntries) {
          if (isOverlapping(timeEntry1, timeEntry2)) {
            return res(
              ctx.status(400),
              ctx.json({
                message: "Time entries are overlapping with another session",
              })
            );
          }
        }
      }
    }

    const id = item.keyId || item.id;
    if (id) {
      const targetIndex = items.findIndex(
        (el: any) => el.keyId === id || el.id === id
      );
      items.splice(targetIndex, 1, item);
    } else {
      item.keyId = uuidv4();
      item.id = item.keyId;
      items.push(item);
    }
    window.localStorage.setItem("agendaSessions", JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),
  ...realHandlerGenerator(
    {
      storage: "agendaSessions",
      fetched: "agendaSessions",
    },
    false
  ),

  ...realHandlerGenerator(
    {
      storage: "providers",
      fetched: "providers",
    },
    false
  ),
  ...realHandlerGenerator(
    {
      storage: "venues",
      fetched: "venues",
    },
    false
  ),
  ...realHandlerGenerator(
    {
      storage: "buildings",
      fetched: "buildings",
    },
    false,
    ["venueId"]
  ),
  ...realHandlerGenerator(
    {
      storage: "levels",
      fetched: "levels",
    },
    false,
    ["venueId"]
  ),
  rest.get(`${baseUrl}/venuestructures`, async (req, res, ctx) => {
    const venueId = req.url.searchParams.get("venueId");

    const buildings: Building[] = JSON.parse(
      window.localStorage.getItem("buildings") || "[]"
    ).filter((el: Building) => el.venueId === venueId);
    const levels: Level[] = JSON.parse(
      window.localStorage.getItem("levels") || "[]"
    );
    const spaces: Space[] = JSON.parse(
      window.localStorage.getItem("spaces") || "[]"
    );

    const response = buildings.map((building: Building) => {
      const children = levels
        .filter((level: Level) => level.buildingId === building.keyId)
        .map((level: Level) => ({
          ...level,
          spaces: spaces.filter(
            (space: Space) => space.levelId === level.keyId
          ),
        }));
      return {
        ...building,
        levels: children,
      };
    });

    // Formatting

    return res(ctx.status(200), ctx.json(response));
  }),

  ...handlerGenerator("costItems"),
  ...handlerGenerator("sponsorKits"),
  ...handlerGenerator("orders"),
  ...handlerGenerator("compositeCostItems"),
  ...handlerGenerator("comments"),
  ...handlerGenerator("headers"),
  ...handlerGenerator("labels"),
  ...handlerGenerator("sponsorCompanies"),
  ...handlerGenerator("sponsorServices"),
  ...handlerGenerator("economicEntities"),
  ...handlerGenerator("prices"),
];

export const handlers = [...newEntities];
