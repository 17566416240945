const NODE_ENV = process.env.NODE_ENV;
const ACTIVE_BRANCH = process.env.VUE_APP_VERCEL_GIT_COMMIT_REF;

const API_URLS: Record<string, string> = {
  dev: "https://2mcyskkfo3.execute-api.eu-west-1.amazonaws.com/api",
  mocked: "https://2mcyskkfo3.execute-api.eu-west-1.amazonaws.com/api",
  uat: "https://g36jc2xrn2.execute-api.eu-west-1.amazonaws.com/api",
  prod: "https://lnafmb6bre.execute-api.eu-west-1.amazonaws.com/api",
};

export const enableMockAPIs = NODE_ENV === "null" || ACTIVE_BRANCH === "mocked";
export const baseUrl = API_URLS[ACTIVE_BRANCH ?? "dev"];
