import { useI18n } from "vue-i18n";
import type { TabStripTabProperties } from "@progress/kendo-vue-layout";
import { computed, Ref } from "vue";

import { baseUrl } from "@/config";

// eslint-disable-next-line
import { useMyFetch, useMySWRV } from "./useMySWRV";

export type Business = {
  accountCode?: string;
  address?: string;
  businessName: string;
  city?: string;
  countryIso3: string;
  county?: string;
  dismissed: boolean;
  keyId: string;
  holdingBusinessId?: string;
  fiscalCode: string;
  individualRelatedId?: string;
  isCustomer: boolean;
  isSupplier: boolean;
  isHolded: boolean;
  isHolding: boolean;
  nickname?: string;
  password?: string;
  phoneNumber?: string;
  province?: string;
  replaced: boolean;
  replacingBusiness?: string;
  taxCode?: string;
  username?: string;
};

export type ApiBusiness = Business;

export const apiToBusiness = (
  fetchedBusiness: ApiBusiness | Omit<ApiBusiness, "keyId">
) => fetchedBusiness as Business;

export const businessToApi = (business: Business | Omit<Business, "keyId">) => {
  return {
    ...business,
    isHolding: !!business.isHolding ?? false,
    isHolded: !!business.holdingBusinessId ?? false,
    dismissed: !!business.dismissed ?? false,
    isCustomer: !!business.isCustomer ?? false,
    isSupplier: !!business.isSupplier ?? false,
    replaced: !!business.replaced ?? false,
  } as ApiBusiness;
};

export const useCustomers = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const {
    data: dataCustomer,
    mutate: mutateCustomers,
    isValidating: isValidatingCustomers,
    error: errorCustomers,
  } = useMySWRV(`${baseUrl}/customers`, {
    urlParams: options?.urlParams,
    headers: options?.headers,
    enabled: options?.enabled,
  });
  const myFetch = useMyFetch();

  const apiCustomers = computed<Business[]>(() => dataCustomer.value?.elements);

  const customersCount = computed(() =>
    parseInt(dataCustomer.value?.paging.totalRecords)
  );

  const customers = computed(() => apiCustomers.value?.map(apiToBusiness));

  const deleteCustomer = async (keyId: string) => {
    const response = await myFetch(`${baseUrl}/customers/${keyId}`, {
      method: "DELETE",
    });
    await mutateCustomers();
    return response;
  };

  const createCustomer = async (data: Omit<Business, "keyId">) => {
    const response = await myFetch(`${baseUrl}/customers`, {
      method: "POST",
      body: businessToApi(data) as unknown as JSON,
    });
    await mutateCustomers();
    return response;
  };

  const editCustomer = async (data: Business) => {
    const response = await myFetch(`${baseUrl}/customers`, {
      method: "POST",
      body: businessToApi(data) as unknown as JSON,
    });
    await mutateCustomers();
    return response;
  };
  const getCustomer = (keyId: string) => {
    return customers.value?.find((el: Business) => el.keyId === keyId);
  };
  const reloadCustomers = () => mutateCustomers();

  return {
    deleteCustomer,
    createCustomer,
    editCustomer,
    customers,
    customersCount,
    mutateCustomers,
    getCustomer,
    isValidatingCustomers,
    errorCustomers,
    reloadCustomers,
  };
};

export const useSuppliers = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
}) => {
  const {
    data: dataSupplier,
    mutate: mutateSuppliers,
    isValidating: isValidatingSuppliers,
    error: errorSuppliers,
  } = useMySWRV(`${baseUrl}/suppliers`, {
    urlParams: options?.urlParams ?? {},
    headers: options?.headers ?? {},
  });
  const myFetch = useMyFetch();

  const apiSuppliers = computed<Business[]>(() => dataSupplier.value?.elements);

  const suppliersCount = computed(() =>
    parseInt(dataSupplier.value?.paging.totalRecords)
  );

  const suppliers = computed(() => apiSuppliers.value?.map(apiToBusiness));
  const deleteSupplier = async (keyId: string) => {
    const response = await myFetch(`${baseUrl}/suppliers/${keyId}`, {
      method: "DELETE",
    });
    await mutateSuppliers();
    return response;
  };

  const createSupplier = async (data: Omit<Business, "keyId">) => {
    const response = await myFetch(`${baseUrl}/suppliers`, {
      method: "POST",
      body: businessToApi(data) as unknown as JSON,
    });
    await mutateSuppliers();
    return response;
  };

  const editSupplier = async (data: Business) => {
    const response = await myFetch(`${baseUrl}/suppliers`, {
      method: "POST",
      body: businessToApi(data) as unknown as JSON,
    });
    await mutateSuppliers();
    return response;
  };

  const getSupplier = (keyId: string) => {
    return suppliers.value?.find((el: Business) => el.keyId === keyId);
  };
  const reloadSuppliers = () => mutateSuppliers();

  return {
    createSupplier,
    editSupplier,
    deleteSupplier,
    getSupplier,
    mutateSuppliers,
    suppliersCount,
    suppliers,
    isValidatingSuppliers,
    errorSuppliers,
    reloadSuppliers,
  };
};
export const useBusinesses = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
}) => {
  const { t } = useI18n();
  const myFetch = useMyFetch();

  const { data, mutate } = useMySWRV(`${baseUrl}/businesses`, {
    headers: options?.headers ?? {},
    urlParams: options?.urlParams ?? {},
  });

  const apiBusinesses = computed<Business[]>(() => data.value?.elements);

  const businessesCount = computed(() =>
    parseInt(data.value?.paging.totalRecords)
  );

  const businesses = computed(() => apiBusinesses.value?.map(businessToApi));
  const apiToBusiness = (business: Business) => business;

  const businessToApi = (business: any) => business;

  const businessesNames = computed(() =>
    businesses.value?.map((el: Business) => el.businessName)
  );

  const getBusiness = (keyId: string) => {
    return businesses.value?.find((el: Business) => el.keyId === keyId);
  };

  const getBusinessName = (keyId: string) =>
    businesses.value?.find((el: Business) => el.keyId === keyId)?.businessName;

  const getBusinessId = (businessName: string) =>
    businesses.value?.find((el: Business) => el.businessName === businessName)
      ?.keyId;

  const businessEditPanelTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "businessAnagraphicsForm",
    },

    /* { {
           id: "1",
           title: t("pages.suppliers.detailPanel.tabs.bank"),
           content: "businessBankForm",
         },
         
      id: "2",
      title: t("pages.suppliers.form.tabs.departmentList.name"),
      content: "businessDepartmentList",
      disabled: true,
    },*/
    {
      id: "1",
      title: t("pages.suppliers.detailPanel.tabs.credentials"),
      content: "businessCredentials",
    },
  ];

  const businessCreationPanelTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "businessAnagraphicsForm",
    },

    /* {{
                              id: "1",
                              title: t("pages.suppliers.detailPanel.tabs.bank"),
                              content: "businessBankForm",
                            },
    
                                                                                                                   id: "2",
                                                                                                                   title: t("pages.suppliers.form.tabs.departmentList.name"),
                                                                                                                   content: "businessDepartmentList",
                                                                                                                 },*/
    {
      id: "1",
      title: t("pages.suppliers.detailPanel.tabs.credentials"),
      content: "businessCredentials",
      disabled: true,
    },
  ];

  const businessCreationPanelFormSelectedTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "businessAnagraphicsForm",
    },
    /*{
                          {
                            id: "1",
                            title: t("pages.suppliers.detailPanel.tabs.bank"),
                            content: "businessBankForm",
                          },
    
                                                                                                                id: "2",
                                                                                                                title: t("pages.suppliers.form.tabs.departmentList.name"),
                                                                                                                content: "businessDepartmentList",
                                                                                                              },*/
    {
      id: "1",
      title: t("pages.suppliers.detailPanel.tabs.credentials"),
      content: "businessCredentials",
    },
  ];
  return {
    businesses,
    businessesCount,
    apiToBusiness,
    businessToApi,
    businessesNames,
    getBusiness,
    getBusinessName,
    getBusinessId,
    businessEditPanelTabs,
    businessCreationPanelTabs,
    businessCreationPanelFormSelectedTabs,
  };
};
