import { computed, Ref } from "vue";
import { TabStripTabProperties } from "@progress/kendo-vue-layout";
import { useI18n } from "vue-i18n";

import { baseUrl } from "@/config";

// eslint-disable-next-line
import { useMyFetch, useMySWRV } from "../../_resources/useMySWRV";
// eslint-disable-next-line no-restricted-imports
import { PortalEntity } from "../../../app/_resources/usePortalEntities";

export type PortalUser = {
  address: string;
  birthdate: string;
  city: string;
  countryIso3: string;
  dateFormat: string;
  defaultProjectRoleDescription: string;
  defaultProjectRoleId: string;
  defaultPortalRoleId: number;
  defaultPortalRoleDescription: string;
  description: string;
  email: string;
  keyId: string;
  languageCode: number;
  mobilePhone: string;
  name: string;
  permissions: PortalEntity[];
  portalUuid: string;
  surname: string;
  suspended: boolean;
  timeFormat: string;
  timeZone: string;
  zipCode: string;
};

export type ApiPortalUser = PortalUser;

export const apiToPortalUser = (
  portalUser: ApiPortalUser | Omit<ApiPortalUser, "keyId">
) => portalUser as PortalUser;

export const portalUserToApi = (
  portalUser: PortalUser | Omit<PortalUser, "keyId">
) => {
  const apiPortalUser = {
    ...portalUser,
    languageCode: parseInt(String(portalUser.languageCode)),
    defaultPortalRoleId: parseInt(String(portalUser.defaultPortalRoleId)),
    suspended: !!portalUser.suspended ?? false,
  };
  return apiPortalUser as ApiPortalUser;
};

export const usePortalUsers = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();
  const { t } = useI18n();

  const {
    data,
    mutate,
    isValidating: isValidatingPortalUserProfiles,
    error: errorPortalUserProfiles,
  } = useMySWRV<PortalUser[]>(`${baseUrl}/portaluserprofiles`, {
    urlParams: options?.urlParams,
    headers: options?.headers,
    enabled: options?.enabled,
  });

  const portalUserEditPanelTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "portalUserGeneralFormContent",
    },
    {
      id: "1",
      title: t("pages.portalUsers.form.tabs.preferences.label"),
      content: "portalUserPreferencesFormContent",
    },
    {
      id: "2",
      title: t("pages.portalUsers.form.tabs.staff.label"),
      content: "portalUserStaffFormContent",
    },
  ];

  const portalUserCreationPanelTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "portalUserGeneralFormContent",
    },
    {
      id: "1",
      title: t("pages.portalUsers.form.tabs.preferences.label"),
      content: "portalUserPreferencesFormContent",
    },
    {
      id: "2",
      title: t("pages.portalUsers.form.tabs.staff.label"),
      content: "portalUserStaffFormContent",
      disabled: true,
    },
  ];

  const portalUsers = computed(() => data.value?.map(apiToPortalUser));

  const deletePortalUser = async (keyId: string) => {
    const response = await myFetch(`${baseUrl}/portaluserprofiles/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const createPortalUser = async (data: Omit<PortalUser, "keyId">) => {
    const response = await myFetch(`${baseUrl}/portaluserprofiles`, {
      method: "POST",
      body: portalUserToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editPortalUser = async (data: PortalUser) => {
    const response = await myFetch(`${baseUrl}/portaluserprofiles`, {
      method: "POST",
      body: portalUserToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const getPortalUser = (portalUserId: string) =>
    portalUsers.value?.find((portalUser) => portalUser.keyId === portalUserId);

  const getPortalUserById = async (portalUserId: string) => {
    const response = await myFetch(
      `${baseUrl}/portaluserprofiles/${portalUserId}`,
      {
        method: "GET",
      }
    );
    return response.json();
  };
  const reloadPortalUser = () => mutate();

  return {
    portalUsers,
    deletePortalUser,
    createPortalUser,
    editPortalUser,
    getPortalUser,
    portalUserEditPanelTabs,
    portalUserCreationPanelTabs,
    reloadPortalUser,
    errorPortalUserProfiles,
    isValidatingPortalUserProfiles,
    getPortalUserById,
  };
};
