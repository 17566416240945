import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";

import { baseUrl } from "@/config";
import { ListItem } from "@/ui/useItemList";

import { useMyFetch, useMySWRV } from "./useMySWRV";

export type Specialization = Profession & {
  disciplines: Discipline[];
};

export type ApiSpecialization = Specialization;

export type Profession = {
  keyId: number;
  professionCode: number;
  description: string;
  isScientific: boolean;
  useForEcm: boolean;
  useForWeb: boolean;
  dismissed: boolean;
};

export type ApiProfession = Profession;

export type Discipline = {
  keyId: number;
  professionId: number;
  description: string;
  disciplineCode: number;
  isScientific: boolean;
  useForEcm: boolean;
  useForWeb: boolean;
  dismissed: boolean;
};

export type ApiDiscipline = Discipline;

export const apiToSpecialization = (
  specialization: ApiSpecialization | Omit<ApiSpecialization, "keyId">
) => specialization as Specialization;

export const specializationToApi = (
  specialization: Specialization | Omit<Specialization, "keyId">
) => {
  const apiSpecialization = {
    ...specialization,
    professionCode: parseInt(String(specialization.professionCode)),
    isScientific: !!specialization.isScientific ?? false,
    useForEcm: !!specialization.useForEcm ?? false,
    useForWeb: !!specialization.useForWeb ?? false,
    dismissed: !!specialization.dismissed ?? false,
    disciplines: specialization?.disciplines?.map(disciplineToApi) ?? [],
  };
  return apiSpecialization as ApiSpecialization;
};

export const useSpecializations = () => {
  const {
    data,
    mutate,
    isValidating: isValidatingSpecializations,
    error: errorSpecializations,
  } = useMySWRV(`${baseUrl}/specializations`, {});
  const { t } = useI18n();

  const specializations = computed(() => data.value?.map(apiToSpecialization));

  const getProfessionDisciplines = (professionId: number) =>
    specializations.value?.find(
      (specialization: Specialization) => specialization.keyId === professionId
    )?.disciplines;

  const specializationsListItems = computed(
    () =>
      specializations.value?.map((specialization: Specialization) => ({
        id: specialization.keyId,
        title: specialization.description,
        description: `${t(
          "pages.specializations.jobForm.fields.code.label"
        )}: ${specialization.professionCode}`,
        children: specialization?.disciplines?.map(
          (discipline: Discipline) =>
            ({
              id: discipline.keyId,
              title: discipline.description,
              description: `${t(
                "pages.specializations.jobForm.fields.code.label"
              )}: ${discipline.disciplineCode}`,
            } as unknown as ListItem)
        ),
      })) ?? []
  );

  const reloadSpecializations = () => mutate();

  return {
    specializations,
    mutateSpecializations: mutate,
    getProfessionDisciplines,
    specializationsListItems,
    isValidatingSpecializations,
    errorSpecializations,
    reloadSpecializations,
  };
};

export const apiToProfession = (
  profession: ApiProfession | Omit<ApiProfession, "keyId">
) => profession as Profession;

export const professionToApi = (
  profession: Profession | Omit<Profession, "keyId">
) => {
  const apiProfession = {
    ...profession,
    professionCode: parseInt(String(profession.professionCode)),
    isScientific: !!profession.isScientific ?? false,
    useForEcm: !!profession.useForEcm ?? false,
    useForWeb: !!profession.useForWeb ?? false,
    dismissed: !!profession.dismissed ?? false,
  };
  return apiProfession as ApiProfession;
};

export const useProfessions = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();

  const { data, mutate } = useMySWRV(`${baseUrl}/professions`, {
    urlParams: options?.urlParams ?? {},
    headers: options?.headers ?? {},
    enabled: options?.enabled,
  });

  const apiProfession = computed(() => data.value?.elements);

  const professionCount = computed(() =>
    parseInt(data.value?.paging.totalRecords)
  );

  const professions = computed(() => apiProfession.value?.map(apiToProfession));

  const createProfession = async (data: Omit<Profession, "keyId">) => {
    const response = await myFetch(`${baseUrl}/professions`, {
      method: "POST",
      body: professionToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editProfession = async (data: Profession) => {
    const response = await myFetch(`${baseUrl}/professions`, {
      method: "POST",
      body: professionToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const deleteProfession = async (keyId: number) => {
    const response = await myFetch(`${baseUrl}/professions/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const getProfessionName = (keyId: number) =>
    professions.value?.find(
      (profession: Profession) => profession.keyId === keyId
    )?.description;

  const getProfession = (keyId: number) =>
    professions.value?.find(
      (profession: Profession) => profession.keyId === keyId
    );

  return {
    professions,
    ProfessionCount: professionCount,
    createProfession,
    editProfession,
    deleteProfession,
    getProfessionName,
    getProfession,
  };
};

export const apiToDiscipline = (
  discipline: ApiDiscipline | Omit<ApiDiscipline, "keyId">
) => discipline as Discipline;

export const disciplineToApi = (
  discipline: Discipline | Omit<Discipline, "keyId">
) => {
  const apiDiscipline = {
    ...discipline,
    professionId: parseInt(String(discipline.professionId)),
    disciplineCode: parseInt(String(discipline.disciplineCode)),
    isScientific: discipline.isScientific ?? false,
    useForEcm: discipline.useForEcm ?? false,
    useForWeb: discipline.useForWeb ?? false,
    dismissed: discipline.dismissed ?? false,
  };

  return apiDiscipline as ApiDiscipline;
};
export const useDisciplines = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();

  const { data, mutate } = useMySWRV(`${baseUrl}/disciplines`, {
    urlParams: options?.urlParams ?? {},
    headers: options?.headers ?? {},
    enabled: options?.enabled,
  });

  const apiDisciplines = computed(() => data.value?.elements);

  const disciplinesCount = computed(() =>
    parseInt(data.value?.paging.totalRecords)
  );

  const disciplines = computed(() =>
    apiDisciplines.value?.map(apiToDiscipline)
  );

  const createDiscipline = async (data: Omit<Discipline, "keyId">) => {
    const response = await myFetch(`${baseUrl}/disciplines`, {
      method: "POST",
      body: disciplineToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editDiscipline = async (data: Discipline) => {
    const response = await myFetch(`${baseUrl}/disciplines`, {
      method: "POST",
      body: disciplineToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const deleteDiscipline = async (keyId: number) => {
    const response = await myFetch(`${baseUrl}/disciplines/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const getDisciplineDescription = (keyId: number) =>
    disciplines.value?.find((el: Discipline) => el.keyId === keyId)
      ?.description;

  const getDiscipline = (keyId: number) =>
    disciplines.value?.find((el: Discipline) => el.keyId === keyId);

  const getDisciplines = (ids: number[]) =>
    ids.map((keyId) => getDiscipline(keyId));

  return {
    disciplines,
    disciplinesCount,
    createDiscipline,
    editDiscipline,
    deleteDiscipline,
    getDisciplineDescription,
    getDiscipline,
    getDisciplines,
  };
};
