import { computed, Ref } from "vue";

import { baseUrl } from "@/config";

// eslint-disable-next-line
import { useMyFetch, useMySWRV } from "./useMySWRV";

export type FetchedTeamMember = Omit<TeamMember, "id"> & {
  keyId: string;
};

export type TeamMember = {
  id: string;
  defaultAclId: string;
  staffId: string;
  teamId: string;
  companyId: string;
  surname: string;
  name: string;
  teamCode: string;
  teamDescription: string;
  email: string;
  businessUnitId: string;
};

export const parseTeamMember = (
  teamMember: FetchedTeamMember | Omit<FetchedTeamMember, "keyId">
) => {
  const parsedTeamMember = {
    ...teamMember,
  };
  // @ts-ignore
  if (teamMember.keyId) {
    // @ts-ignore
    delete parsedTeamMember.keyId;
    // @ts-ignore
    parsedTeamMember.id = teamMember.keyId;
  }

  return parsedTeamMember as TeamMember;
};

export const formatTeamMember = (
  teamMember: TeamMember | Omit<TeamMember, "id">
) => {
  const formattedTeamMember = {
    ...teamMember,
  };
  // @ts-ignore
  if (teamMember.id) {
    // @ts-ignore
    delete formattedTeamMember.id;
    // @ts-ignore
    formattedTeamMember.keyId = teamMember.id;
  }
  return formattedTeamMember as FetchedTeamMember;
};

export const useTeamMembers = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
}) => {
  const myFetch = useMyFetch();

  const { data, mutate } = useMySWRV(`${baseUrl}/teammembers`, {
    urlParams: options?.urlParams ?? {},
    headers: options?.headers ?? {},
  });

  const fetchedTeamMembers = computed<FetchedTeamMember[]>(
    () => data.value?.elements
  );

  const teamMembersCount = computed(() =>
    parseInt(data.value?.paging.totalRecords)
  );

  const teamMembers = computed(() =>
    fetchedTeamMembers.value?.map(parseTeamMember)
  );

  const deleteTeamMember = async (id: string) => {
    const response = await myFetch(`${baseUrl}/teammembers/${id}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const createTeamMember = async (data: Omit<TeamMember, "id">) => {
    const response = await myFetch(`${baseUrl}/teammembers`, {
      method: "POST",
      body: formatTeamMember(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editTeamMember = async (data: TeamMember) => {
    const response = await myFetch(`${baseUrl}/teammembers`, {
      method: "POST",
      body: formatTeamMember(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const getTeamsMembers = async (teamIds: string[]) => {
    const teamsMembers: { teamId: string; teamMembers: TeamMember[] }[] = [];

    for (const teamId of teamIds) {
      const response = await myFetch(`${baseUrl}/teammembers`, {
        method: "GET",
        urlParams: {
          teamId,
        },
      });
      const { elements } = await response.json();
      teamsMembers.push({
        teamId,
        teamMembers: elements.map(parseTeamMember),
      });
    }

    return teamsMembers;
  };

  return {
    teamMembers,
    teamMembersCount,
    createTeamMember,
    deleteTeamMember,
    editTeamMember,
    getTeamsMembers,
  };
};
