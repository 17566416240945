import { computed, Ref } from "vue";

import { baseUrl } from "@/config";

// eslint-disable-next-line
import { useMyFetch, useMySWRV } from "./useMySWRV";

export type FetchedDepartment = {
  keyId: string;
  businessId: string;
  description: string;
  code: string;
  address: string;
  zipCode: string;
  city: string;
  province: string;
  countryIso3: string;
  phone: string;
  fax: string;
};

export type Department = {
  id: string;
  businessId: string;
  description: string;
  code: string;
  address: string;
  zipCode: string;
  city: string;
  province: string;
  countryIso3: string;
  phone: string;
  fax: string;
};

export const parseDepartment = (
  department: FetchedDepartment | Omit<FetchedDepartment, "keyId">
) => {
  const parsedDepartment = {
    description: department.description,
    code: department.code,
    address: department.address,
    zipCode: department.zipCode,
    city: department.city,
    province: department.province,
    countryIso3: department.countryIso3,
    phone: department.phone,
    fax: department.fax,
    businessId: department.businessId,
  };
  // @ts-ignore
  if (department.keyId) parsedDepartment.id = department.keyId;
  // @ts-ignore
  return parsedDepartment as Department;
};

export const formatDepartment = (
  department: Department | Omit<Department, "id">
) => {
  const formattedDepartment = {
    description: department.description,
    code: department.code,
    address: department.address,
    zipCode: department.zipCode,
    city: department.city,
    province: department.province,
    countryIso3: department.countryIso3,
    phone: department.phone,
    fax: department.fax,
    businessId: department.businessId,
  };

  // @ts-ignore
  if (department.id) formattedDepartment.keyId = department.id;

  // @ts-ignore
  return formattedDepartment as FetchedDepartment;
};

export const useDepartments = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();

  const { data, mutate } = useMySWRV(`${baseUrl}/departments`, {
    urlParams: options?.urlParams ?? {},
    headers: options?.headers ?? {},
    enabled: options?.enabled,
  });

  const fetchedDepartments = computed<FetchedDepartment[]>(
    () => data.value?.elements
  );

  const departmentsCount = computed(() =>
    parseInt(data.value?.paging.totalRecords)
  );

  const departments = computed(() =>
    fetchedDepartments.value?.map(parseDepartment)
  );

  const deleteDepartment = async (id: string) => {
    const response = await myFetch(`${baseUrl}/departments/${id}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const createDepartment = async (data: Omit<Department, "id">) => {
    const response = await myFetch(`${baseUrl}/departments`, {
      method: "POST",
      body: formatDepartment(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editDepartment = async (data: Department) => {
    const response = await myFetch(`${baseUrl}/departments`, {
      method: "POST",
      body: formatDepartment(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const departmentsNames = computed(() =>
    departments.value?.map((el: Department) => el.description)
  );

  const getDepartmentId = (description: string) =>
    departments.value?.find((el: Department) => el.description === description)
      ?.id;

  const getDepartmentName = (id: string) =>
    departments.value?.find((el: Department) => el.id === id)?.description;

  const getDepartment = (id: string) =>
    departments.value?.find((el) => el.id === id);

  return {
    departments,
    deleteDepartment,
    createDepartment,
    editDepartment,
    departmentsNames,
    getDepartmentId,
    getDepartmentName,
    departmentsCount,
    getDepartment,
  };
};
