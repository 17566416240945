import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { TabStripTabProperties } from "@progress/kendo-vue-layout/dist/esm/main";

import { baseUrl } from "@/config";

// eslint-disable-next-line
import { useMyFetch, useMySWRV } from "./useMySWRV";
// eslint-disable-next-line no-restricted-imports
import { Company } from "../../app/_resources/useCompanies";

export type CompanyTeam = {
  keyId: string;
  companyId: string;
  description: string;
  code: string;
  teamLeaderId: string;
  businessUnitId: string;
};

export type ApiCompanyTeam = CompanyTeam;

export const apiToCompanyTeam = (
  companyTeam: ApiCompanyTeam | Omit<ApiCompanyTeam, "keyId">
) => companyTeam as CompanyTeam;

export const companyTeamToApi = (
  companyTeamApi: CompanyTeam | Omit<CompanyTeam, "keyId">
) => companyTeamApi as ApiCompanyTeam;

export const useCompanyTeams = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enable?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();
  const { t } = useI18n();

  const {
    data,
    mutate,
    isValidating: isValidatingCompanyTeams,
    error: errorCompanyTeams,
  } = useMySWRV(`${baseUrl}/companyteams`, {
    urlParams: options?.urlParams,
    headers: options?.headers,
    enabled: options?.enable,
  });

  const apiCompanyTeams = computed(() => data.value?.elements);

  const companyTeamsCount = computed(() =>
    parseInt(data.value?.paging.totalRecords)
  );

  const companyTeams = computed(() =>
    apiCompanyTeams.value?.map(apiToCompanyTeam)
  );

  const deleteCompanyTeam = async (keyId: number) => {
    const response = await myFetch(`${baseUrl}/companyteams/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const createCompanyTeam = async (data: Omit<CompanyTeam, "keyId">) => {
    const response = await myFetch(`${baseUrl}/companyteams`, {
      method: "POST",
      body: companyTeamToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editCompanyTeam = async (data: CompanyTeam) => {
    const response = await myFetch(`${baseUrl}/companyteams`, {
      method: "POST",
      body: companyTeamToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const companyTeamCreationPanelTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "teamGeneralForm",
    },
    {
      id: "1",
      title: t("pages.companies.teamForm.tabs.members.name"),
      content: "teamUserForm",
      disabled: true,
    },
  ];

  const companyTeamEditPanelTabs: TabStripTabProperties[] = [
    {
      id: "0",
      title: t("form.tabs.main"),
      content: "teamGeneralForm",
    },
    {
      id: "1",
      title: t("pages.companies.teamForm.tabs.members.name"),
      content: "teamMembersForm",
    },
  ];

  const getCompanyTeamDescription = (keyId: string) =>
    companyTeams.value?.find((el: Company) => el.keyId === keyId)?.description;

  const getCompanyTeamId = (name: string) =>
    companyTeams.value?.find((team: CompanyTeam) => team.description === name)
      ?.keyId;

  const getCompanyTeam = (keyId: string) =>
    companyTeams.value?.find((team: CompanyTeam) => team.keyId === keyId);

  return {
    companyTeams,
    companyTeamsCount,
    createCompanyTeam,
    deleteCompanyTeam,
    editCompanyTeam,
    getCompanyTeamDescription,
    getCompanyTeam,
    getCompanyTeamId,
    companyTeamCreationPanelTabs,
    companyTeamEditPanelTabs,
    isValidatingCompanyTeams,
    errorCompanyTeams,
  };
};
