import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";

import { baseUrl } from "@/config";

import { useMyFetch, useMySWRV } from "./useMySWRV";

type Team = {
  keyId: number;
  description?: string;
};

export type Staff = {
  companies?: {
    keyId: string;
    description?: string;
    teams?: Team[];
    businessUnits?: {
      keyId: number;
      description?: string;
      teams?: Team[];
    }[];
  }[];
  portalUserUuid?: string;
  surname: string;
  notOperative: boolean;
  name: string;
  keyId: number;
  email: string;
};

export type ApiStaff = Staff;

export const apiToStaff = (apiStaff: ApiStaff | Omit<ApiStaff, "keyId">) =>
  apiStaff as Staff;

export const staffToApi = (staff: Staff | Omit<Staff, "keyId">) => {
  const staffs = {
    ...staff,
    notOperative: !!staff.notOperative ?? false,
  };

  return staffs as ApiStaff;
};

export const useStaff = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();
  const { t } = useI18n();

  const {
    data: dataStaff,
    mutate,
    isValidating: isValidatingStaff,
    error: errorStaff,
  } = useMySWRV(`${baseUrl}/staff`, {
    urlParams: options?.urlParams,
    headers: options?.headers,
    enabled: options?.enabled,
  });

  const apiStaff = computed(() => dataStaff.value ?? []);
  const staff = computed(() => apiStaff.value?.map(apiToStaff));

  const portalUserStaff = computed(() =>
    staff.value?.filter((el: Staff) => el.portalUserUuid)
  );

  const createStaff = async (data: Omit<Staff, "keyId">) => {
    const response = await myFetch(`${baseUrl}/staff`, {
      method: "POST",
      body: staffToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const deleteStaff = async (keyId: number) => {
    const response = await myFetch(`${baseUrl}/staff/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const editStaff = async (data: Staff) => {
    const response = await myFetch(`${baseUrl}/staff`, {
      method: "POST",
      body: staffToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const getStaffName = (keyId: number) =>
    staff.value?.find((staff: Staff) => staff.keyId === keyId)?.name;

  const getStaffUnitId = (name: string) =>
    staff.value?.find((staff: Staff) => staff.name === name)?.keyId;

  const getStaff = (keyId: number) =>
    staff.value?.find((staff: Staff) => staff.keyId === keyId);

  const getStaffFromPortalUser = (keyId: number) => {
    return staff.value?.find(
      (el: Staff) =>
        el.portalUserUuid?.toLocaleLowerCase() ===
        keyId.toString().toLocaleLowerCase()
    );
  };

  const addStaffToCompany = async (staffId: number, companyId: string) => {
    const response = await myFetch(`${baseUrl}/companystaff`, {
      method: "POST",
      body: {
        staffId,
        companyId,
      } as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const removeStaffFromCompany = async (companyId: string, staffId: string) => {
    const response = await myFetch(`${baseUrl}/companystaff`, {
      method: "DELETE",
      body: {
        staffId,
        companyId,
      } as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const addStaffToBusinessUnit = async (
    businessUnitId: string,
    staffId: string
  ) => {
    const response = await myFetch(`${baseUrl}/businessunitstaff`, {
      method: "POST",
      body: {
        staffId,
        businessUnitId,
      } as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const removeStaffFromBusinessUnit = async (
    businessUnitId: string,
    staffId: string
  ) => {
    const response = await myFetch(`${baseUrl}/businessunitstaff`, {
      method: "DELETE",
      body: {
        staffId,
        businessUnitId,
      } as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const getStaffFromCompany = (companyId: string) => {
    return staff.value?.filter((staff: Staff) =>
      staff?.companies?.find((company) => company.keyId === companyId)
    );
  };

  const addStaffToTeam = async (teamId: string, staffId: string) => {
    const response = await myFetch(`${baseUrl}/teammember`, {
      method: "POST",
      body: {
        teamId,
        staffId,
      } as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const removeStaffFromTeam = async (teamId: string, staffId: string) => {
    const response = await myFetch(`${baseUrl}/teammember`, {
      method: "DELETE",
      body: {
        teamId,
        staffId,
      } as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const isStaffInTeam = (teamId: string, staffId: number) => {
    let isStaffInTeam = false;

    const targetStaff = staff.value?.find(
      (staff: Staff) => staff.keyId === staffId
    );

    if (targetStaff) {
      targetStaff?.companies?.forEach((company: any) => {
        if (company.teams?.find((team: any) => team.keyId === teamId)) {
          isStaffInTeam = true;
        }
        company.businessUnits?.forEach((businessUnit: any) => {
          if (businessUnit.teams?.find((team: any) => team.keyId === teamId)) {
            isStaffInTeam = true;
          }
        });
      });
    }
    return isStaffInTeam;
  };

  const getStaffFullName = (keyId: number) => {
    const targetStaff =
      staff.value?.find((staff: Staff) => staff.keyId === keyId) ?? "";
    return targetStaff ? `${targetStaff.name} ${targetStaff.surname}` : "";
  };

  return {
    staff,
    portalUserStaff,
    apiStaff,
    createStaff,
    deleteStaff,
    editStaff,
    getStaffName,
    getStaffUnitId,
    getStaff,
    addStaffToCompany,
    removeStaffFromCompany,
    addStaffToBusinessUnit,
    removeStaffFromBusinessUnit,
    getStaffFromCompany,
    getStaffFromPortalUser,
    addStaffToTeam,
    removeStaffFromTeam,
    isStaffInTeam,
    getStaffFullName,
    isValidatingStaff,
    errorStaff,
  };
};
