import { computed, ref, type Ref, watch } from "vue";
import useSWRV, { type IConfig, mutate } from "swrv";

import { useAuth } from "./useAuth";

export const useMyFetch = () => {
  const { refreshToken } = useAuth();

  return async (
    path: string,
    options: {
      method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
      urlParams?: {
        [key: string]: string;
      };
      headers?: {
        [key: string]: string;
      };
      body?: any;
    }
  ) => {
    const getHeaders = () => {
      const defaultHeaders: {
        [key: string]: string;
      } = {
        authorizationToken: `Bearer ${localStorage.getItem("jwt")}` || "",
        accept: "application/json",
      };

      if (options.method !== "DELETE") {
        defaultHeaders["Content-Type"] = "application/json";
      }
      return {
        ...defaultHeaders,
        ...options.headers,
      };
    };

    const cleanObject = (obj: any) => {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        } else if (Array.isArray(obj[key])) {
          obj[key] = obj[key].map((item: any) => {
            if (typeof item === "object") {
              return cleanObject(item);
            } else {
              return item;
            }
          });
        } else if (typeof obj[key] === "object") {
          cleanObject(obj[key]);
        }
      }
      return obj;
    };

    const cleanBody = (body: any) => {
      if (body && options.method === "POST") {
        const bodyObj = JSON.parse(body);
        return JSON.stringify(cleanObject(bodyObj));
      }
      return body;
    };

    const request = async () => {
      return await fetch(
        path + "?" + new URLSearchParams(options.urlParams ?? {}),
        {
          method: options.method,
          headers: getHeaders(),
          body: options.body ? cleanBody(JSON.stringify(options.body)) : null,
        }
      );
    };

    let response = await request();

    if (response.status === 401 || response.status === 403) {
      //@ts-ignore
      const { status } = await refreshToken();
      if (status === 200) {
        response = await request();
      } else {
        throw new Error("Unauthorized");
      }
    }

    /* if (!response.ok) {
                                                 throw new Error(response.statusText);
                                               }*/

    return response;
  };
};

export const useMySWRV = <T = any>(
  path: string | Ref<string>,
  options: {
    key?: Ref;
    method?: "GET" | "POST" | Ref<"GET" | "POST">;
    urlParams?:
      | {
          [key: string]: string;
        }
      | Ref<{
          [key: string]: string;
        }>;
    headers?:
      | {
          [key: string]: string;
        }
      | Ref<{
          [key: string]: string;
        }>;
    body?: JSON | Ref<JSON>;
    SWRVOptions?: IConfig;
    enabled?: boolean | Ref<boolean>;
  }
) => {
  const myFetch = useMyFetch();

  const {
    key,
    method = "GET",
    urlParams = {},
    headers = {},
    body,
    SWRVOptions,
    enabled = true,
  } = options;

  const reactivePath = ref(path);
  const reactiveMethod = ref(method);
  const reactiveUrlParams = ref(urlParams);
  const reactiveBody = ref(body);
  const reactiveHeaders = ref(headers);
  const reactiveEnabled = ref(enabled);

  const SWRVKey = computed(() => {
    if (key) {
      return key.value;
    } else {
      return JSON.stringify({
        method: reactiveMethod.value,
        path: reactivePath.value,
        urlParams: reactiveUrlParams.value,
        body: reactiveBody.value,
        headers: reactiveHeaders.value,
      });
    }
  });

  const apiCall = async () => {
    if (reactiveEnabled.value) {
      return (
        await myFetch(reactivePath.value, {
          method: reactiveMethod.value,
          urlParams: reactiveUrlParams.value,
          headers: reactiveHeaders.value,
          body: reactiveBody.value,
        })
      ).json();
    }
  };

  const { data, error, isValidating, mutate } = useSWRV<T>(SWRVKey, apiCall, {
    ...SWRVOptions,
    revalidateOnFocus: location.href.includes("localhost")
      ? false
      : SWRVOptions?.revalidateOnFocus,
  });

  return {
    data,
    mutate,
    isValidating,
    error,
  };
};
