import { ref } from "vue";
import { useI18n } from "vue-i18n";

export type Notification = {
  id?: string;
  title: string;
  description?: string;
  icon: boolean;
  theme: "none" | "success" | "warning" | "info" | "error";
  closable: boolean;
  show?: boolean;
  onClose?: () => void;
  onClick?: () => void;
};

const notifications = ref<Notification[]>([]);

export const useNotifications = () => {
  const { t } = useI18n();

  const showNotification = (notification: Notification) => {
    const id = Math.random().toString();
    notification.id = id;
    notification.show = true;
    notification.onClose = () => {
      notifications.value.find((el) => el.id === id)!.show = false;
      setTimeout(() => {
        notifications.value = notifications.value.filter((el) => el.id !== id);
      }, 1000);
    };
    notifications.value.push(notification);
    setTimeout(() => {
      notifications.value.find((el) => el.id === id)!.show = false;
      setTimeout(() => {
        notifications.value = notifications.value.filter((el) => el.id !== id);
      }, 1000);
    }, 5000);
  };

  const handleResponseNotification = async (
    response: Response,
    successMessage = t("fetch.messages.success"),
    successTheme: "success" | "warning" | "info" | "error" = "success",
    errorMessage?: string,
    errorOnly = false
  ) => {
    if (response.ok && successMessage && !errorOnly) {
      showSuccessNotification(successMessage);
    } else if (!response.ok) {
      let message = errorMessage || t("fetch.messages.error");
      try {
        message = (await response.json())?.message;
      } finally {
        showErrorNotification(message);
      }
    }
  };

  const showSuccessNotification = (
    successMessage = t("fetch.messages.success")
  ) => {
    showNotification({
      title: successMessage,
      icon: true,
      theme: "success",
      closable: true,
    });
  };

  const showErrorNotification = (errorMessage = t("fetch.messages.error")) => {
    showNotification({
      title: errorMessage,
      icon: true,
      theme: "error",
      closable: true,
    });
  };

  return {
    showNotification,
    handleResponseNotification,
    notifications,
    showSuccessNotification,
    showErrorNotification,
  };
};
