import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { rest, RESTMethods } from "msw";
import { v4 as uuidv4 } from "uuid";

import { baseUrl } from "@/config";

export const realHandlerGenerator = <ApiEntity>(
  entityName: {
    fetched: string;
    storage: string;
  },
  pagination = true,
  filters: string[] = []
) => [
  rest.get(`${baseUrl}/${entityName.fetched}`, async (req, res, ctx) => {
    let items: ApiEntity[] = JSON.parse(
      window.localStorage.getItem(entityName.storage) || "[]"
    );
    if (filters.length > 0) {
      filters.forEach((filter) => {
        const filterValue = req.url.searchParams.get(filter);
        if (filterValue) {
          items = items.filter((el: any) => el[filter] === filterValue);
        }
      });
    }

    // Filtering
    if (req.headers.get("X-Header-Filter")) {
      const filter: CompositeFilterDescriptor = JSON.parse(
        atob(req.headers.get("X-Header-Filter") as string)
      );
      items = filterBy(items, filter);
    }

    // Formatting
    let formattedItems = items;
    let response;
    // Pagination
    if (pagination) {
      const pageNumber = parseInt(
        req.url.searchParams.get("pageNumber") as string
      );
      const pageSize = parseInt(req.url.searchParams.get("pageSize") as string);
      if (pageNumber > 0 && pageSize > 0) {
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        formattedItems = formattedItems.slice(start, end);
      }
      response = {
        elements: formattedItems,
        paging: {
          totalRecords: items.length || 0,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      };
    } else {
      response = formattedItems;
    }

    return res(ctx.status(200), ctx.json(response));
  }),
  rest.get(`${baseUrl}/${entityName.fetched}/:keyId`, async (req, res, ctx) => {
    const { keyId } = req.params;
    const items: ApiEntity[] = JSON.parse(
      window.localStorage.getItem(entityName.storage) || "[]"
    );
    const response = items.find(
      (el: any) => el.id === keyId || el.keyId === keyId
    );

    return res(ctx.status(200), ctx.json(response));
  }),
  rest.post(`${baseUrl}/${entityName.fetched}`, async (req, res, ctx) => {
    const item = await req.json();
    const items: ApiEntity[] = JSON.parse(
      window.localStorage.getItem(entityName.storage) || "[]"
    );
    const id = item.keyId || item.id;
    if (id) {
      const targetIndex = items.findIndex(
        (el: any) => el.keyId === id || el.id === id
      );
      items.splice(targetIndex, 1, item);
    } else {
      item.keyId = uuidv4();
      item.id = item.keyId;
      items.push(item);
    }
    window.localStorage.setItem(entityName.storage, JSON.stringify(items));
    return res(ctx.status(200), ctx.json(item));
  }),

  rest.delete(
    `${baseUrl}/${entityName.fetched}/:keyId`,
    async (req, res, ctx) => {
      const { keyId } = req.params;
      const items: ApiEntity[] = JSON.parse(
        window.localStorage.getItem(entityName.storage) || "[]"
      );
      const targetIndex = items.findIndex(
        (el: any) => el.id === keyId || el.keyId === keyId
      );
      items.splice(targetIndex, 1);
      window.localStorage.setItem(entityName.storage, JSON.stringify(items));
      return res(ctx.status(200), ctx.json({}));
    }
  ),
];
