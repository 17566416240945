import { computed, Ref } from "vue";

import { baseUrl } from "@/config";

// eslint-disable-next-line
import { useMyFetch, useMySWRV } from "./useMySWRV";

export type ProjectCategory = Macroarea & {
  microareas: Microarea[];
};

export type ApiProjectCategories = ProjectCategory;

export type Macroarea = {
  keyId: number;
  description: string;
};

export type ApiMacroarea = Macroarea;

export type Microarea = {
  keyId: number;
  description: string;
  macroTypeId: number;
};

export type ApiMicroarea = Microarea;

export const apiToProjectCategory = (
  apiProjectCategories:
    | ApiProjectCategories
    | Omit<ApiProjectCategories, "keyId">
) => apiProjectCategories as ProjectCategory;

export const useProjectCategories = () => {
  const {
    data,
    mutate: mutateProjectCategories,
    isValidating: isValidatingProjectCategories,
    error: errorCategories,
  } = useMySWRV(`${baseUrl}/projectcategories`, {});

  const apiProjectCategories = computed(() =>
    data.value?.map(apiToProjectCategory)
  );

  const reloadProjectCategories = () => mutateProjectCategories();

  return {
    apiProjectCategories,
    mutateProjectCategories,
    isValidatingProjectCategories,
    errorCategories,
    reloadProjectCategories,
  };
};

export const apiToMacroarea = (
  macroarea: ApiMacroarea | Omit<ApiMacroarea, "keyId">
) => macroarea as Macroarea;

export const macroareaToApi = (
  macroarea: Macroarea | Omit<Macroarea, "keyId">
) => macroarea as ApiMacroarea;

export const useMacroareas = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();

  const { data, mutate } = useMySWRV(`${baseUrl}/macroareas`, {
    urlParams: options?.urlParams,
    headers: options?.headers,
    enabled: options?.enabled,
  });

  const apiMacroareas = computed(() => data?.value?.elements);

  const macroareasCount = computed(() =>
    parseInt(data?.value?.paging.totalRecords)
  );

  const macroareas = computed(() => apiMacroareas.value?.map(apiToMacroarea));

  const createMacroarea = async (data: Omit<Macroarea, "keyId">) => {
    const response = await myFetch(`${baseUrl}/macroareas`, {
      method: "POST",
      body: macroareaToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editMacroarea = async (data: Macroarea) => {
    const response = await myFetch(`${baseUrl}/macroareas`, {
      method: "POST",
      body: macroareaToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const deleteMacroarea = async (keyId: number) => {
    const response = await myFetch(`${baseUrl}/macroareas/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const getMacroareaName = (keyId: number) =>
    macroareas.value?.find((macroarea: Macroarea) => macroarea.keyId === keyId)
      ?.description;

  const getMacroarea = (keyId: number) =>
    macroareas.value?.find((macroarea: Macroarea) => macroarea.keyId === keyId);

  return {
    macroareas,
    macroareasCount,
    createMacroarea,
    editMacroarea,
    deleteMacroarea,
    getMacroareaName,
    getMacroarea,
  };
};

export const apiToMicroarea = (
  microarea: ApiMicroarea | Omit<ApiMicroarea, "keyId">
) => microarea as Microarea;

export const microareaToApi = (
  apiMicroarea: Microarea | Omit<Microarea, "keyId">
) => apiMicroarea as ApiMicroarea;
export const useMicroareas = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  headers?:
    | {
        [key: string]: string;
      }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const myFetch = useMyFetch();

  const { data, mutate } = useMySWRV(`${baseUrl}/microareas`, {
    urlParams: options?.urlParams,
    headers: options?.headers,
    enabled: options?.enabled,
  });

  const apiMicroarea = computed<ApiMicroarea[]>(() => data.value?.elements);

  const microareaCount = computed(() =>
    parseInt(data.value?.paging.totalRecords)
  );

  const microareas = computed(() => apiMicroarea.value?.map(apiToMicroarea));

  const createMicroarea = async (data: Omit<Microarea, "keyId">) => {
    const response = await myFetch(`${baseUrl}/microareas`, {
      method: "POST",
      body: microareaToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const editMicroarea = async (data: Microarea) => {
    const response = await myFetch(`${baseUrl}/microareas`, {
      method: "POST",
      body: microareaToApi(data) as unknown as JSON,
    });
    await mutate();
    return response;
  };

  const deleteMicroarea = async (keyId: number) => {
    const response = await myFetch(`${baseUrl}/microareas/${keyId}`, {
      method: "DELETE",
    });
    await mutate();
    return response;
  };

  const getMicroareaDescription = (keyId: number) =>
    microareas.value.find((el: Microarea) => el.keyId === keyId)?.description;

  const getMicroarea = (keyId: number) =>
    microareas.value.find((el: Microarea) => el.keyId === keyId);

  return {
    microareas,
    microareaCount,
    createMicroarea,
    editMicroarea,
    deleteMicroarea,
    getMicroareaDescription,
    getMicroarea,
  };
};
