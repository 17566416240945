import { computed, Ref } from "vue";

import { baseUrl } from "@/config";

// eslint-disable-next-line
import { useMySWRV } from "./useMySWRV";

export type FetchedProjectUsers = {
  staff: {
    businessUnits: {
      teams: {
        keyId: number;
        description: string;
      }[];
      keyId: number;
      description: string;
    }[];
    portalUuid: string;
    defaultProjectRoleId: number;
    email: string;
    keyId: string;
    surname: string;
    name: string;
  }[];
  roles: {
    roleCode: string;
    keyId: string;
    description: string;
  }[];
};

export type ProjectUsers = {
  staff: {
    businessUnits: {
      teams: {
        id: number;
        description: string;
      }[];
      id: number;
      description: string;
    }[];
    portalUuid: string;
    defaultProjectRoleId: number;
    email: string;
    id: string;
    surname: string;
    name: string;
  }[];
  roles: {
    roleCode: string;
    id: string;
    description: string;
  }[];
};

export const parseProjectUsers = (
  projectUsers: FetchedProjectUsers | Omit<FetchedProjectUsers, "keyId">
) => {
  const parsedProjectUsers = {
    staff: projectUsers.staff?.map((staff) => {
      const parsedStaff = {
        ...staff,
        businessUnits: staff.businessUnits?.map((businessUnit) => {
          const parsedBusinessUnit = {
            ...businessUnit,
            id: businessUnit.keyId,
            teams: businessUnit.teams?.map((team) => {
              const parsedTeam = { ...team, id: team.keyId };
              // @ts-ignore
              delete parsedTeam.keyId;
              return parsedTeam;
            }),
          };
          // @ts-ignore
          delete parsedBusinessUnit.keyId;
          return parsedBusinessUnit;
        }),
        id: staff.keyId,
      };
      // @ts-ignore
      delete parsedStaff.keyId;
      return parsedStaff;
    }),
    roles: projectUsers.roles?.map((role) => ({
      description: role.description,
      id: role.keyId,
      roleCode: role.roleCode,
    })),
  };
  // @ts-ignore
  return parsedProjectUsers as ProjectUsers;
};

export const formatProjectUsers = (
  projectUsers: ProjectUsers | Omit<ProjectUsers, "id">
): FetchedProjectUsers => {
  const formattedProjectUsers = {
    staff: projectUsers.staff?.map((staff) => {
      const formattedStaff = {
        ...staff,
        businessUnits: staff.businessUnits?.map((businessUnit) => {
          const formattedBusinessUnit = {
            ...businessUnit,
            keyId: businessUnit.id,
            teams: businessUnit.teams?.map((team) => {
              const formattedTeam = { ...team, keyId: team.id };
              // @ts-ignore
              delete formattedTeam.id;
              return formattedTeam;
            }),
          };
          // @ts-ignore
          delete formattedBusinessUnit.id;
          return formattedBusinessUnit;
        }),
        keyId: staff.id,
      };
      // @ts-ignore
      delete formattedStaff.id;
      return formattedStaff;
    }),
    roles: projectUsers.roles?.map((role) => ({
      description: role.description,
      keyId: role.id,
      roleCode: role.roleCode,
    })),
  };
  return formattedProjectUsers as FetchedProjectUsers;
};

export const useProjectUsers = (options?: {
  urlParams?:
    | { [key: string]: string }
    | Ref<{
        [key: string]: string;
      }>;
  enabled?: boolean | Ref<boolean>;
}) => {
  const { data, mutate } = useMySWRV(`${baseUrl}/projectusers`, {
    urlParams: options?.urlParams ?? {},
    enabled: options?.enabled,
  });

  const projectUsers = computed(() =>
    data.value ? parseProjectUsers(data.value) : undefined
  );

  return {
    projectUsers,
    mutateProjectUsers: mutate,
  };
};
