/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import LocalStorage from "@/app/localStorage/_page.vue";
import { useAuth } from "@/app/_resources/useAuth";
import Home from "@/app/_page.vue";
import PermissionDenied from "@/app/403/_page.vue";
import { Entity } from "@/app/_resources/usePermissions";
import { usePermissions } from "@/app/_resources/usePermissions";
import { useStorage } from "@vueuse/core";

const { canRead } = usePermissions();
const jwt = useStorage("jwt", null);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/localstorage",
      name: "localstorage",
      component: LocalStorage,
      meta: {
        prevRoute: null,
      },
    },
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        prevRoute: null,
      },
    },
    {
      path: "/localStorage",
      name: "localStorage",
      component: LocalStorage,
      meta: {
        prevRoute: null,
      },
    },
    {
      path: "/403",
      name: "403",
      component: PermissionDenied,
      meta: {
        prevRoute: null,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/app/login/_page.vue"),
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("@/app/orders/_page.vue"),
      meta: {
        prevRoute: "home",
      },
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("@/app/projects/_page.vue"),
      meta: {
        prevRoute: "home",
      },
    },
    {
      path: "/portalUsers",
      name: "portalUsers",
      component: () => import("@/app/portalUsers/_page.vue"),
      meta: {
        prevRoute: "home",
        enabledEntity: Entity.portalUser,
      },
    },
    {
      path: "/portalRoles",
      name: "portalRoles",
      component: () => import("@/app/portalRoles/_page.vue"),
      meta: {
        prevRoute: "home",
        enabledEntity: Entity.portalRole,
      },
    },
    {
      path: "/projectRoles",
      name: "projectRoles",
      component: () => import("@/app/projectRoles/_page.vue"),
      meta: {
        prevRoute: "home",
        enabledEntity: Entity.projectRole,
      },
    },
    {
      path: "/projects/:projectId",
      name: "projectDashboard",
      component: () => import("@/app/projects/[projectId]/_page.vue"),
      meta: {
        prevRoute: "projects",
      },
    },
    {
      path: "/projects/create",
      name: "projectCreate",
      component: () => import("@/app/projects/projectCreate/_page.vue"),
      meta: {
        prevRoute: "projects",
      },
    },
    {
      path: "/projects/:projectId/economicManagement",
      name: "economicManagement",
      component: () =>
        import("@/app/projects/[projectId]/economicManagement/_page.vue"),
      meta: {
        prevRoute: "projectDashboard",
      },
    },
    {
      path: "/projects/:projectId/economicManagement/:economicEntityId",
      name: "economicEntityDetail",
      component: () =>
        import(
          "@/app/projects/[projectId]/economicManagement/[economicEntityId]/_page.vue"
        ),
      meta: {
        prevRoute: "economicManagement",
      },
    },
    {
      path: "/projects/:projectId/economicManagement/:economicEntityId/:sponsorEstimateId",
      name: "sponsorEstimateDetail",
      component: () =>
        import(
          "@/app/projects/[projectId]/economicManagement/[economicEntityId]/[sponsorEstimateId]/_page.vue"
        ),
      meta: {
        prevRoute: "economicEntityDetail",
      },
    },
    {
      path: "/projects/:projectId/economicManagement/servicesManagement",
      name: "budgetServicesManagement",
      component: () =>
        import(
          "@/app/projects/[projectId]/economicManagement/servicesManagement/_page.vue"
        ),
      meta: {
        prevRoute: "economicManagement",
      },
    },
    {
      path: "/projects/:projectId/economicManagement/:economicEntityId/servicesManagement",
      name: "sponsorBudgetServicesManagement",
      component: () =>
        import(
          "@/app/projects/[projectId]/economicManagement/[economicEntityId]/servicesManagement/_page.vue"
        ),
      meta: {
        prevRoute: "economicEntityDetail",
      },
    },
    {
      path: "/projects/:projectId/economicManagement/estimatesArchive",
      name: "estimatesArchive",
      component: () =>
        import(
          "@/app/projects/[projectId]/economicManagement/estimatesArchive/_page.vue"
        ),
      meta: {
        prevRoute: "economicManagement",
      },
    },
    {
      path: "/projects/:projectId/economicManagement/estimatesArchive/:estimateId",
      name: "archivedEstimateDetail",
      component: () =>
        import(
          "@/app/projects/[projectId]/economicManagement/estimatesArchive/[estimateId]/_page.vue"
        ),
      meta: {
        prevRoute: "estimatesArchive",
      },
    },

    {
      path: "/projects/:projectId/economicManagement/estimatesArchive/:estimateId/:sponsorEstimateId",
      name: "archivedSponsorEstimateDetail",
      component: () =>
        import(
          "@/app/projects/[projectId]/economicManagement/estimatesArchive/[estimateId]/[sponsorEstimateId]/_page.vue"
        ),
      meta: {
        prevRoute: "archivedEstimateDetail",
      },
    },
    {
      path: "/projects/:projectId/participantsCategories",
      name: "participantsCategories",
      component: () =>
        import("@/app/projects/[projectId]/participantsCategories/_page.vue"),
      meta: {
        prevRoute: "projectDashboard",
      },
    },
    {
      path: "/projects/:projectId/sponsorServices",
      name: "sponsorServices",
      component: () =>
        import("@/app/projects/[projectId]/sponsorServices/_page.vue"),
      meta: {
        prevRoute: "projectDashboard",
      },
    },
    {
      path: "/projects/:projectId/sponsorCompanies",
      name: "sponsorCompanies",
      component: () =>
        import("@/app/projects/[projectId]/sponsorCompanies/_page.vue"),
      meta: {
        prevRoute: "projectDashboard",
      },
    },
    {
      path: "/projects/:projectId/sponsorKits",
      name: "sponsorKits",
      component: () =>
        import("@/app/projects/[projectId]/sponsorKits/_page.vue"),
      meta: {
        prevRoute: "projectDashboard",
      },
    },
    {
      path: "/projects/:projectId/classes",
      name: "classes",
      component: () => import("@/app/projects/[projectId]/classes/_page.vue"),
      meta: {
        prevRoute: "projectDashboard",
      },
    },
    {
      path: "/projects/:projectId/classes/:classId",
      name: "classDetail",
      component: () =>
        import("@/app/projects/[projectId]/classes/[classId]/_page.vue"),
      meta: {
        prevRoute: "classes",
      },
    },
    {
      path: "/projects/:projectId/classes/create",
      name: "classCreate",
      component: () =>
        import("@/app/projects/[projectId]/classes/classCreate/_page.vue"),
      meta: {
        prevRoute: "classes",
      },
    },
    {
      path: "/backoffice/",
      name: "backofficeDashboard",
      component: () => import("@/app/backoffice/_page.vue"),
      meta: {
        prevRoute: "home",
      },
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("@/app/backoffice/companies/_page.vue"),
      meta: {
        prevRoute: "home",
        enabledEntity: Entity.company,
      },
    },
    {
      path: "/backoffice/countries",
      name: "countries",
      component: () => import("@/app/backoffice/countries/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.country,
      },
    },
    {
      path: "/backoffice/projectStates",
      name: "projectStates",
      component: () => import("@/app/backoffice/projectStates/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.projectStatus,
      },
    },
    {
      path: "/backoffice/contactStates",
      name: "contactStates",
      component: () => import("@/app/backoffice/contactStates/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
      },
    },

    {
      path: "/suppliers",
      name: "suppliers",
      component: () => import("@/app/backoffice/suppliers/_page.vue"),
      meta: {
        prevRoute: "home",
        enabledEntity: Entity.supplier,
      },
    },
    {
      path: "/individuals",
      name: "individuals",
      component: () => import("@/app/backoffice/individuals/_page.vue"),
      meta: {
        prevRoute: "home",
        enabledEntity: Entity.participant,
      },
    },
    {
      path: "/customers",
      name: "customers",
      component: () => import("@/app/backoffice/customers/_page.vue"),
      meta: {
        prevRoute: "home",
        enabledEntity: Entity.customer,
      },
    },
    {
      path: "/backoffice/paymentMethods",
      name: "paymentMethods",
      component: () => import("@/app/backoffice/paymentMethods/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
      },
    },
    {
      path: "/backoffice/budgetClasses",
      name: "budgetClasses",
      component: () => import("@/app/backoffice/budgetClasses/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.budgetClass,
      },
    },
    {
      path: "/backoffice/honorificTitles",
      name: "honorificTitles",
      component: () => import("@/app/backoffice/honorificTitles/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.honorificTitle,
      },
    },
    {
      path: "/backoffice/projectCategories",
      name: "projectCategories",
      component: () => import("@/app/backoffice/projectCategories/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.macroarea,
      },
    },
    {
      path: "/backoffice/trainingTargets",
      name: "trainingTargets",
      component: () => import("@/app/backoffice/trainingTargets/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.trainingTarget,
      },
    },
    {
      path: "/backoffice/trainingTypologies",
      name: "trainingTypologies",
      component: () => import("@/app/backoffice/trainingTypologies/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.trainingType,
      },
    },
    {
      path: "/backoffice/providers",
      name: "providers",
      component: () => import("@/app/backoffice/providers/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.provider,
      },
    },
    {
      path: "/backoffice/specializations",
      name: "specializations",
      component: () => import("@/app/backoffice/specializations/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.profession,
      },
    },
    {
      path: "/backoffice/taxConfigurations",
      name: "taxConfigurations",
      component: () => import("@/app/backoffice/taxConfigurations/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: Entity.taxContext,
      },
    },
    {
      path: "/companies/:companyId",
      name: "companyDetail",
      component: () =>
        import("@/app/backoffice/companies/[companyId]/_page.vue"),
      meta: {
        prevRoute: "companies",
        enabledEntity: Entity.company,
      },
    },
    {
      path: "/backoffice/venues",
      name: "venues",
      component: () => import("@/app/backoffice/venues/_page.vue"),
      meta: {
        prevRoute: "backofficeDashboard",
        enabledEntity: true,
      },
    },
    {
      path: "/backoffice/venues/:venueId",
      name: "venueDetail",
      component: () => import("@/app/backoffice/venues/[venueId]/_page.vue"),
      meta: {
        prevRoute: "venues",
        enabledEntity: true,
      },
    },
  ],
});

router.beforeEach((to, from) => {
  if (!jwt.value && to.name !== "login") {
    return { name: "login" };
  } else if (to.meta.enabledEntity) {
    const canAccess = canRead(to.meta.enabledEntity as Entity);
    return canAccess ? true : { name: "403" };
  }
});

export default router;
